#login {
	background-color: $body-bg;

	.navbar {
		position: relative;
		background-color: $primary;

		.logo {
			height: rem-calc(30);
		}
	}

	.login-wrapper {
		background-color: $body-bg;

		@include media-breakpoint-up(lg) {
			background-color: $primary;
		}

		.inner-wrapper {

			@include media-breakpoint-up(lg) {
				padding-top: rem-calc(30);
			}

			@media screen and ( min-height: 900px ) {
				min-height: 100vh;
				padding-top: 0;
			}

			.box-wrapper {
				padding-bottom: rem-calc(100);

				@media screen and ( min-height: 900px ) {
					padding-bottom: rem-calc(160);
				}

				@include media-breakpoint-up(lg) {
					min-height: rem-calc(720);
				}
			}

			.graphic {
				padding: rem-calc(25 0);
				height: rem-calc(150);

				@include media-breakpoint-up(md) {
					height: rem-calc(200);
				}

				@include media-breakpoint-up(lg) {
					padding-top: rem-calc(30);
					height: rem-calc(380);
				}
			}

			.tagline {
				color: $primary;
				font-weight: $font-weight-bold;
				padding-bottom: rem-calc(20);

				@include media-breakpoint-down(lg) {
					font-size: $font-size-md;
				}

				@include media-breakpoint-up(lg) {
					padding-bottom: 0;
				}
			}
		}

		.graphic-bg {
			background-color: $body-bg;
			align-items: center;
			justify-content: center;

			.inner {
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			}
		}

		.login-form {

			.inner {
				padding: rem-calc(50 20);

				@include media-breakpoint-up(lg) {
					padding: rem-calc(115 20);
				}
			}
		}

		.footer {
			padding: rem-calc(20 0);
			margin-top: rem-calc(15);

			@include media-breakpoint-up(lg) {
				color: rgba($gray-100, 0.5);
			}

			@media screen and ( min-height: 900px ) {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				margin-top: rem-calc(50);
			}

			a {
				
				@include media-breakpoint-up(lg) {
					color: rgba($gray-100, 0.5);
				}
			}
		}
	}

	#divArtwork {
		background-size: cover;
		background-position: center center;
		height: rem-calc(300);
		margin-bottom: rem-calc(37);

		@include media-breakpoint-up(lg) {
			height: auto;
			margin-bottom: 0;
		}

		.artwork-label {
			position: absolute;
		    bottom: rem-calc(-37);
		    left: 0;
		    right: 0;
		    text-align: center;
		    color: $primary;
		    background-color: white;

		    @include media-breakpoint-up(lg) {
		    	color: white;
		    	background-color: transparent;	
		    	bottom: rem-calc(-48);
    		}

		    p {
		    	padding-top: rem-calc(12);

		    	@include media-breakpoint-up(lg) {
		    		padding-top: 0;
		    	}
		    }
		}
	}
}
