html body .RadInput_Simple .riTextBox, html body .RadInputMgr_Simple {
    border-color: $input-border-color !important;
    background: $input-bg !important;
    color: $input-color !important;
    font: $input-font-weight $input-btn-font-size $font-family-sans-serif !important;
    padding: $input-padding-y $input-padding-x !important;

    &.white-sm {

        @include media-breakpoint-down(md) {
            border-color: white !important;
            background: white !important;
        }
    }

    &:focus {
    	border-color: $input-focus-border-color !important;
    }
}

.riSingle .riTextBox, .RadForm.rfdTextbox .riSingle input.rfdDecorated[type="text"] {
	height: $input-height !important;
}

.RadInput_Simple, .RadInputMgr_Simple {
    width: auto !important;
    display: block !important;
}

.RadInput_Simple, .RadInputMgr_Simple {
    font-family: $font-family-sans-serif !important;
    font-size: $font-size-base !important;
}