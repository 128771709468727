.select2-container {

	.select2-choice {
		color: $body-color;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		padding: $input-btn-padding-y $input-btn-padding-x;

		abbr {
			top: 0;
		    bottom: 0;
		    position: absolute;
		    display: block;
		    height: 100%;
		    width: 35px;
		    right: 0;

			&.select2-search-choice-close {
				background: url('../Assets/images/cross.svg') !important;
				background-size: rem-calc(15) !important;
				background-repeat: no-repeat !important;
				background-position: 10px 50% !important;
			}
		}
	}

	&.select2-container-active {

		abbr {
			top: 0;
		    bottom: 0;
		    position: absolute;
		    display: block;
		    height: 100%;
		    width: 35px;
		    right: 0;

			&.select2-search-choice-close {
				background: url('../Assets/images/cross.svg') !important;
				background-size: rem-calc(15) !important;
				background-repeat: no-repeat !important;
				background-position: 10px 50% !important;
			}
		}
	}
}

.select2-drop {
	border: none;
	box-shadow: rem-calc(0 0 10) rgba($body-color, 0.3);

	.select2-search {
		padding: rem-calc(15 25);

		input {
			padding: rem-calc(10 15);
			background-image: url('../Assets/images/search.svg') !important;
			border-radius: $border-radius;
			border-color: rgba($body-color, 0.25);
			font-family: $font-family-sans-serif;
			background-position: 98% 50% !important;
			background-size: rem-calc(15) !important;
			color: $body-color;
		}
	}

	.select2-results {
		margin: 0;
		padding: 0;

		.select2-no-results {
			padding: rem-calc(10) $dropdown-item-padding-x rem-calc(20) $dropdown-item-padding-x;
			color: $body-color;
			background-color: transparent;
		}

		.select2-highlighted {
			background-color: $primary;

			.select2-result-label {
				color: white;
			}
		}

		.select2-result-label {
			padding: $dropdown-item-padding-y $dropdown-item-padding-x;
			color: $body-color;
			font-weight: $font-weight-normal;
		}
	}
}

.select2-default {
    color: rgba($body-color, 0.6) !important;
}