.dashboard {
	transition: all 0.3s;

	* {
		transition: all 0.3s;
	}

	.wip {
		position: relative;

		.wip-text {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
		    align-items: center;
		    justify-content: center;
		    flex-direction: column;

		    &.left {

		    	.inner {
		    		padding-right: 12%;
		    	}
		    }

		    &.right {

		    	.inner {
		    		padding-left: 12%;
		    	}
		    }
		}
	}

	h2 {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	.badge {
		font-size: $font-size-base;
		position: relative;
		margin-left: rem-calc(35);

		@include media-breakpoint-up(md) {
			margin-left: rem-calc(10);
			top: rem-calc(-3);
		}
	}

	.view-all {
		color: rgba($gray-700, 0.5);
		transition: color 0.2s;

		&:hover {
			text-decoration: none;
			color: rgba($gray-700, 0.75);
		}
	}

	.card {
		border: none;
		border-radius: rem-calc(4);
		padding: rem-calc(20);

		&.bordered {
			border: 1px solid $gray-100;
			padding: rem-calc(50 18);
		}

		&.stats {

			img {

				@include media-breakpoint-up(lg) {
					width: rem-calc(115);
				}

				@include media-breakpoint-up(xxl) {
					width: rem-calc(80);
				}
			}
		}

		@include media-breakpoint-up(md) {
			padding: rem-calc(35);
		}

		&.note {
			border: 1px solid $gray-100;
			border-left: 3px solid $gray-100;
			padding: 0;

			&.warning {
				border-left-color: $warning;

				.date {
					color: $warning;
					font-weight: $font-weight-bold;
				}
			}

			&.danger {
				border-left-color: $danger;

				.date {
					color: $danger;
					font-weight: $font-weight-bold;
				}
			}
		}
	}

	.display-1 {

		&.responsive {
			font-size: 8.5vw;

			@include media-breakpoint-up(sm) {
				font-size: $display1-size;
			}

			@include media-breakpoint-up(xl) {
				font-size: 2.6vw;
			}

			@include media-breakpoint-up(xxl) {
				font-size: $display1-size;
			}
		}
	}

	.display-2 {

		&.responsive {
			font-size: 8.5vw;

			@include media-breakpoint-up(sm) {
				font-size: $display2-size;
			}

			@include media-breakpoint-up(xl) {
				font-size: 2.6vw;
			}

			@include media-breakpoint-up(xxl) {
				font-size: $display2-size;
			}
		}
	}


	.circle-icon {
		width: rem-calc(92);
		height: rem-calc(92);
		background-color: rgba($electric-blue, 0.2);
		border-radius: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&.large {
			position: relative;
			width: rem-calc(92);
			height: rem-calc(92);

			@include media-breakpoint-up(lg) {
				width: 100%;
				height: auto;
			}

			&:after {
			  content: "";
			  display: block;
			  padding-bottom: 100%;
			}

			i {
				font-size: rem-calc(40);

				@include media-breakpoint-up(lg) {
					font-size: rem-calc(80);
				}
			}
		}

		i {
			font-size: rem-calc(40);
			line-height: 0.9;
			color: $electric-blue;
		}

		&.warning {
			background-color: rgba($yellow, 0.2);

			i {
				color: $yellow;
			}
		}

		&.success {
			background-color: rgba($electric-green, 0.2);

			i {
				color: $electric-green;
			}
		}
	}

	&.technicians {

		.chart-wrapper {

			.label {
				max-width: rem-calc(170);
			}
		}

		.work-order {

			.card-body {
				display: block;
				justify-content: space-between;

				@include media-breakpoint-up(lg) {
					display: flex;
				}
			}
		}

		.wo {
			font-weight: $font-weight-bold;
			color: $dark-navy;
			margin-right: rem-calc(30);
		}
	}

	&.supervisor-dashboard {
		
		.card {

			&.stats {

				img {
					width: 7rem;
				}
			}
		}
	}
}