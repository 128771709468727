.template-new-site-details {

	.tbNewNode {
	    width: 250px;
	    top: 2px;
	}
	
	#additionalSettings .RadTreeView_Metro .rtEdit .rtIn {
	    border-color: #ccc;
	    font-size: 11px;
	}

	.hidecontrols {
	    position: fixed;
	    top: -100px;
	    left: -100px;
	}

	#contentPlaceHolder_ddlDivision {
	    display: inline-block;
	}

	#contentPlaceHolder_ddlWorkArea {
	    display: inline-block;
	}

	#mapContainer {
	    height: 500px;
	    border: 1px solid #ccc;
	    border-radius: 4px;
	}

	.ddlMin {
	    min-width: 182px;
	}

	.rddlPopup_Simple {
	    padding: 6px 12px;
	    font-size: 12px;
	    color: #555;
	    background-color: #fff;
	    border: 1px solid #ccc;
	    border-radius: 4px;
	    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	}

	.rddlItem {
	    padding: 6px 12px;
	    font-size: 12px;
	    line-height: 1.428571429;
	    height: 20px !important;
	}
}