.dropdown {

	.dropdown-toggle {
		text-align: left;
		position: relative;

		&:after {
			position: absolute;
		    right: rem-calc(15);
		    content: '\e8bf';
		    font-family: fontello;
		    border-top: none;
		    border-bottom: none;
		    border-right: none;
		    border-left: none;
		    color: $body-color;
		    line-height: 2;
		}
	}

	&.show {

		.dropdown-toggle {

			&:after {
				content: '\e8c0';
			}
		}
	}

	.dropdown-menu {
		width: 100%;

		.nav {
			display: block;

			.nav-item {
				margin: 0;
			}
		}
	}
}