.pad { padding: 5px; }
.timesheetApproved {
    position: relative;
    color:green;
}
.timesheetNotApproved {
    position: relative;
    padding-left: .5em;
    color:red;
}
.needsInvoiced { 
    color: #c09853;
    position: relative;
    padding-left: .5em;
}

.grid-cb input{ position: static !important;}
.openVariation {
    position: relative;
    color:red;
    padding-left: .5em;
}
.noTimesheets {
    position: relative;
    color: orange;
    padding-left: .5em;
}
.nopad{ padding-right:0px !important; }
.noright { right:0px !important; }
.nopadding { padding:0px 0px 0px 0px !important; height:100%; }
.noclick{ cursor:default; height:100%; width:100%; }
.padtop { padding-top:8px !important;}
.inheritheight{ height:inherit; }
.cbHeader { margin: 0px 10px 0px 0px !important; }