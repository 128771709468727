.chart-wrapper {
	position: relative;

	canvas {
		position: relative;
		z-index: $layer-1;
	}

	.description {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		div {
			text-align: center;
		}

		&.small {

			.number {
				font-size: rem-calc(25);
			}
		}

		.number {
			font-size: rem-calc(45);
			color: $dark-navy;
			display: block;
			line-height: 1;
		}

		.label {
			font-size: rem-calc(16);
			display: block;
		}
	}

}

.chart-legend {

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		.circle {
			display: inline-block;
			width: rem-calc(9);
			height: rem-calc(9);
			border-radius: 100%;
		}

		.description {
			margin-left: rem-calc(5);
			display:inline-block;
		}

		li {
			font-size: rem-calc(16);

			&:hover {
				cursor: pointer;
			}
		}
	}

	&.small {

		li {
			font-size: rem-calc(14);
		}
	}
}

#workOrdersFault {
	min-height: rem-calc(300) !important;
	width: 100% !important;
}

#worksCompleted {
	min-height: rem-calc(465) !important;
	max-height: rem-calc(465) !important;
	width: 100% !important;
}

#workOrdersCause {
	min-height: rem-calc(215) !important;
	width: 100% !important;
}