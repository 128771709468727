h1 {
	font-size: $h1-font-size-sm;

	@include media-breakpoint-up(md) {
		font-size: $h1-font-size;
	}
}

h2 {
	font-size: $h2-font-size-sm;

	&.display-1 {
		font-size: rem-calc(18);

		@include media-breakpoint-up(md) {
			font-size: $h2-font-size;
		}
	}

	@include media-breakpoint-up(md) {
		font-size: $h2-font-size;
	}
}

h3 {
	font-size: $h3-font-size-sm;

	@include media-breakpoint-up(md) {
		font-size: $h3-font-size;
	}
}

h4 {
	font-size: $h4-font-size-sm;

	@include media-breakpoint-up(md) {
		font-size: $h4-font-size;
	}
}

h5 {
	font-size: $h5-font-size-sm;

	@include media-breakpoint-up(md) {
		font-size: $h5-font-size;
	}
}

h6 {
	font-size: $h6-font-size-sm;

	@include media-breakpoint-up(md) {
		font-size: $h6-font-size;
	}
}