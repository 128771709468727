.form-control {

	&.RadPicker {
		position: relative;
		border: none;

		.rcTable {
			width: 100%;

			tbody {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				tr {
					height: $input-height;
					position: relative;
					display: flex;
				    flex-direction: row;
				    align-items: center;

				    &:hover {

				    	td {
				    		background-color: transparent;
				    	}
				    }
				}

				.rcInputCell {
					height: $input-height;
					position: relative;

					.riTextBox {
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						padding: $input-btn-padding-y $input-btn-padding-x;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						border: none;
						border-top-right-radius: $border-radius;
						border-bottom-right-radius: $border-radius;

						&:focus {
							box-shadow: none;
							border: none;
						}
					}
				}

				.rcCalPopup {
				    width: rem-calc(42);
				    background: url('../Assets/icons/calendar.svg') no-repeat right #{rem-calc(7)} center/#{rem-calc(8 10)};
				    background-size: rem-calc(20);
				    border: none;
				}
			}
		}
	}
}

.RadHtmlChart_Bootstrap {

	canvas {
		position: relative;
		width: 100% !important;
		
		&:before {

			content: '';
			display: block;
			padding-bottom: 100%;
		}
	}
}