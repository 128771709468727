.navbar-toggler {

	&:focus {
	    outline: none;
	}

	&:hover {
		opacity: 1;

		.hamburger {
			opacity: 1;
		}
	}

	.hamburger {
		border: #{rem-calc(2)} solid white;
		border-radius: 100%;
		display: flex;
		width: rem-calc(33);
	    height: rem-calc(33);
	    align-items: center;
	    justify-content: center;
	    padding: 0;
	}
}