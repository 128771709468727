.hide {
	display: none !important;
}

.click-ignore {
	pointer-events:none;
}

.flex-1 {
	flex: 1;
}

.w-md-auto {

	@include media-breakpoint-up(md) {
		width: auto !important;
	}
}

.br-top-right-md-0 {

	@include media-breakpoint-up(md) {
		border-top-right-radius: 0;
	}
}

.br-bottom-right-md-0 {

	@include media-breakpoint-up(md) {
		border-bottom-right-radius: 0;
	}
}

.br-top-right-1 {
	border-top-right-radius: $border-radius !important;
}

.br-bottom-right-1 {
	border-bottom-right-radius: $border-radius !important;
}

.br-top-left-md-0 {

	@include media-breakpoint-up(md) {
		border-top-left-radius: 0;
	}
}

.br-bottom-left-md-0 {

	@include media-breakpoint-up(md) {
		border-bottom-left-radius: 0;
	}
}

.br-top-left-lg-0 {

	@include media-breakpoint-up(lg) {
		border-top-left-radius: 0;
	}
}

.br-bottom-left-lg-0 {

	@include media-breakpoint-up(lg) {
		border-bottom-left-radius: 0;
	}
}

.display-1 {
	line-height: 1.2;
}

.justify-space-between {
	justify-content: space-between;
}

.text-dark-navy {
	color: $dark-navy !important;
}

.hidden {
	display: none;
}

.padding-sm {

	@include media-breakpoint-up(md) {
		padding-left: 8px;
		padding-right: 8px;
	}
}

.min-66 {

	@include media-breakpoint-up(md) {
		min-height: rem-calc(66);
	}
}

.min-66-lg {

	@include media-breakpoint-up(lg) {
		min-height: rem-calc(66);
	}
}

.min-60-xxl {

	@include media-breakpoint-up(xxl) {
		min-height: rem-calc(60);
	}

	@media only screen and (min-width: 2314px) {
		min-height: auto;
	}
}

.mh-auto {
	min-height: auto;
}

.pl-sm {
	padding-left: rem-calc(7.5) !important;
}

.pr-sm {
	padding-right: rem-calc(7.5) !important;
}

.w-3 {
	width: 3%;
}

.w-6 {
	width: 6%;
}

.w-30 {
	width: 30% !important;
}

.w-30-px {
	width: rem-calc(30);
}

.h-30-px {
	height: rem-calc(30);
}

.w-60 {
	width: 60% !important;
}

.w-20 {
	width: 20% !important;
}

.w-15 {
	width: 15% !important;
}

.w-12-2 {
	width: 12.2%;
}

.w-14-8 { 
	width: 14.8%;	
}

.w-14-95 { 
	width: 14.95%;	
}

.w-15-95 { 
	width: 15.95%;	
}

.w-15-15 { 
	width: 15.15%;	
}

.w-15-25 { 
	width: 15.25%;	
}

.w-15-05 {
	width: 15.05%;
}

.w-16 {
	width: 16%;
}

.w-10 {
	width: 10% !important;
}

.w-5 {
	width: 5% !important;
}

.mw-275 {
	min-width: 275px;
}

.mw-206 {
	min-width: 206px;
}

.mw-156 {
	min-width: 156px;
}

.w-80 {
	width: 80% !important;
}

.w-lg-auto {

	@include media-breakpoint-up(lg) {
		width: auto !important;
	}
}

.col-xl-7-items {

	@include media-breakpoint-up(xl) {
		flex: 0 0 14.28571%;
	    max-width: 14.28571%;
	    padding-right: rem-calc(5);
	    padding-left: rem-calc(5);
	}
}

.border-transparent {
	border-color: transparent !important;
}

.color-inherit {
	color: inherit;
}

.nav-item-mobile {
	cursor: pointer;
}

.th {
	color: $table-head-color;
	font-weight: $font-weight-bolder;
}

.font-size-inherit {
	font-size: inherit;
}

.overflow-x-scroll-sm {
	overflow-x: scroll;

	@include media-breakpoint-up(xl) {
		overflow-x: visible;
	}
}

.mw-55 {
	min-width: rem-calc(55);
}

.mw-65 {
	min-width: rem-calc(65);
}

.mw-67 {
  min-width: rem-calc(67);
}

.mw-200 {
	max-width: rem-calc(250);
}

.mw-250 {
	min-width: rem-calc(250);
}

.mw-350 {
	min-width: rem-calc(350);
}

.InActive {
	color: $warning;
}

.fs-20 {
	font-size: rem-calc(20);
}

.fs-14 {
	font-size: rem-calc(14);
}

textarea {

	&.form-control  {

		&.h-237 {
			
			@include media-breakpoint-up(xl) {
				height: rem-calc(237);
			}
		}
	}
}

.no-pointer {
	cursor: default !important;
}

.flex-lg-auto {

	@include media-breakpoint-up(xl) {
		flex: auto;
	}
}

.w-70 {
	width: 70%;
}

.w-100 {
	
	&.w-xl-auto {

		@include media-breakpoint-up(xl) {
			width: auto !important;
		}
	}
}

.w-120px {
	width: 110px !important;
	min-width: 110px !important;
}

.w-150px {
	width: 140px !important;
	min-width: 140px !important;
}

.h-xl-100 {

	@include media-breakpoint-up(xl) {
		height: 100%;
	}
}

.h-700 {
	height: rem-calc(700);
}

.h-350 {
	height: rem-calc(350);
}

.mw-170 {
	min-width: rem-calc(170);
}

.cursor-default {
	cursor: default;
}

.o-auto {
	overflow: auto;
}

.btn-lg-default {

	@include media-breakpoint-up(lg) {
		padding: $btn-padding-y $btn-padding-x !important;
	}
}

.responsive {
	max-width: 100%;
	overflow-x: auto;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-y-scroll {
	overflow-y: scroll;
}

.h-104 {
	height: rem-calc(104);
}

.h-117 {
	height: rem-calc(117);
}

.h-31 {
	height: rem-calc(31);
}

.justify-xl-space-between {

	@include media-breakpoint-up(xxl) {
		justify-content: space-between;
	}
}

.hidecontrols {
    position: fixed;
    top: rem-calc(-100);
    left: rem-calc(-100);
}

.retired {
    color: firebrick;
}

.p-relative {
	position: relative !important;
}

.flag {
	display: none;
}

.white-space-no-wrap {
	white-space: nowrap !important;
}

.white-space-normal {
	white-space: normal;
}

.text-right-md {

	@include media-breakpoint-up(md) {
		text-align: right;
	}
}

.text-light-alt {
	color: rgba($gray-700, 0.5);
}

.text-medium {
	color: $gray-700;
}

.alert-warning {

	a {
		color: #804d0f;
	}
}

.border-right-lg {

	@include media-breakpoint-up(lg) {
		border-right: 1px solid $gray-300;
	}
}

.mw-525 {
	max-width: rem-calc(400);

	@include media-breakpoint-up(md) {
		max-width: rem-calc(600);
	}

	@include media-breakpoint-up(lg) {
		max-width: rem-calc(870);
	}

	@include media-breakpoint-up(xl) {
		max-width: none;
	}
}

.td-container {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}

.td-centered-div {
	position: absolute;
	align-items: center;
	text-align: center;
}

.side-span {	
	align-self: center;
	padding-top: 5px;
	margin-left: 50px;
}

.active4x10 {
	background-color: $butterfields-blue !important;
	opacity: 0.75;
}