.badge {
	padding: rem-calc(4 4 5 4);

	label {
		color: inherit;
		margin: 0;
	}

	&.badge-light {
		color: $body-color;
	}

	&.badge-yellow {
		background-color: $yellow;
		color: white;
	}
}