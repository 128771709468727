#mapContainer {
    height: rem-calc(600);
    border: 1px solid #ccc;
    border-radius: 4px;

    .popover {
        width: 280px;
        top: -108%;
        left: 32px;

        .popover-header {
            padding-top: 11px;
        }

        .btnClosePopover {
            position: absolute;
            right: 8px;
            top: 8px;

            &:hover {
                cursor: pointer;
                text-decoration: none;
            }
        }
    }

    .marker {
        cursor: pointer;

        img {
            position: relative;
            z-index: -1;
        }
    }
}