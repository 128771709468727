.note {
	position: relative;

	.card-body {
		padding: $card-spacer-x rem-calc(40) $card-spacer-x $card-spacer-x;

		@include media-breakpoint-up(lg) {
			padding: $card-spacer-x;
		}
	}

	.delete {
		// position: absolute;
		// top: rem-calc(10);
		// right: rem-calc(10);
		// display: block;

		&:focus {
			box-shadow: none;
		}
	}
}