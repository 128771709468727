.RadCalendar {

	caption {
		display: none;
	}

	.rcTitlebar {

		td {
			background-color: transparent;
		}
	}

	tbody {
	    
	    tr {

	    	&:hover {

	    		td {
	    			background-color: transparent;
	    		}
	    	}
	    }
	}
}

.RadCalendar_Bootstrap {
	box-shadow: rem-calc(0 6 12) rgba($gray-700, 0.5) !important;

	tbody {
		font-family: $font-family-base;
	}

	.rcWeek {

		th {
			color: $dark-navy !important;
			font-weight: $font-weight-bolder !important;
		}
	}

	.rcRow {

		td {
			border-radius: rem-calc(5);

			a {
				color: $body-color !important;
			}

			&.rcOtherMonth {

				a {
					color: rgba($body-color, 0.5) !important;
				}

				&.rcHover {

					a {
					    color: white !important;
					}
				}
			}
		}

		.rcHover {

			a {
			    border-color: $primary !important;
			    background-color: $primary !important;
			    box-shadow: none !important;
			    color: white !important;
			    border: none !important;
			}
		}
	}

	.rcTitlebar {
		border: none !important;

		.rcTitle {
			line-height: 2.6;
		    font-weight: $font-weight-bolder;
		    color: $dark-navy;
		}

		table {
			padding: rem-calc(5 0);
			background-color: $gray-100;
		}

		.rcFastPrev {
			background-image: url('../Assets/icons/double-arrows-left.svg') !important;
			background-position: rem-calc(10) !important;
		}

		.rcPrev {
			background-image: url('../Assets/icons/arrow-left.svg') !important;
			background-position: rem-calc(7 9) !important;
			background-size: rem-calc(10);
		}

		.rcNext {
			background-image: url('../Assets/icons/arrow-right.svg') !important;
			background-position: rem-calc(12 9) !important;
			background-size: rem-calc(10);
		}

		.rcFastNext {
			background-image: url('../Assets/icons/double-arrows-right.svg') !important;
			background-position: rem-calc(11) !important;
		}

		.rcPrev, .rcNext, .rcFastPrev, .rcFastNext {

			&:hover {
				border-color: $gray-200 !important;
				background-color: $gray-200 !important;
			}
		}
	}

	.rcMain {
		border-color: $white !important;

		.rcRow {

			.rcSelected {

				a {
					background-color: $primary !important;
					border-color: $primary !important;
					color: white !important;
				}
			}
		}
	}

	.rcMainTable {
		padding: rem-calc(15);
		font: 14px $font-family-base !important;
	}
}

.RadCalendarPopupShadows {

	&>.rcShadTR, &>.rcShadBL, &>.rcShadBR {
		display: none;
	}
}

.RadCalendarMonthView_Bootstrap {
	font-family: $font-family-base !important;
	padding: rem-calc(15);

	.rcSelected {

		a {
			background-color: $primary !important;
			border-color: $primary !important;
			color: white !important;

			&:hover {
				background-color: $primary !important;
				border-color: $primary !important;
				color: white !important;
			}
		}
	}

	#rcMView_OK {
		background-color: $primary !important;
		border-color: $primary !important;
		color: white !important;
	}

	a {
		color: $body-color !important;

		&:hover {
			border-color: $gray-200 !important;
			background-color: $gray-200 !important;
		}
	}

	.rcButtons {
		padding: rem-calc(15 0 0 0) !important;

		input {
			font-family: $font-family-base !important;
			color: $body-color !important;
			line-height: 2.3;

			&:hover {
				border-color: $gray-200 !important;
				background-color: $gray-200 !important;
				color: $body-color !important;
			}

			&:focus {
				outline: none;
			}
		}
	}

	tr {

		td {
			padding: rem-calc(2 7) !important;
		}
	}
}