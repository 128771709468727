.btn {

	&.aspNetDisabled {
		opacity: $btn-disabled-opacity;

		&.btn-light {
			color: $btn-link-disabled-color;
		}
	}

	@include media-breakpoint-down(sm) {

		&:not(.btn-sm) {
			width: 100%;
		}
	}

	&.with-tick {

		&:before {
			content: '\e8a6';
			font-family: fontello;
		}
	}

	&.btn-light {
		background-color: $white;
		border-color: rgba($gray-700, 0.25);
		color: $gray-700;

		&:hover {
			background-color: lighten($gray-700, 45%);
		}
	}

	&.btn-danger {

		&.outline {
			color: $danger;
			background-color: transparent;

			&:hover {
				background-color: $danger;
				color: $white;
			}
		}
	}

	&.btn-warning {

		&.outline {
			color: $warning;
			background-color: transparent;

			&:hover {
				background-color: $warning;
				color: $white;
			}
		}
	}

	&.btn-white {
		background-color: $white;

		&:hover {
			background-color: rgba($white, 0.5);
		}
	}

	&.btn-square {
		width: rem-calc(30);
		padding-left: 0;
		padding-right: 0;

		i {
			font-size: rem-calc(12);
		}
	}

	&.btn-square-alt {
		padding-top: rem-calc(3);
		padding-bottom: rem-calc(3);
	}
}

.header-controls {

	.btn {

		@include media-breakpoint-down(sm) {
			border-color: $gray-200;
		}
	}
}

.btn-xl-sm {
	
	@include media-breakpoint-up(xl) {
		padding: $btn-padding-y-sm $btn-padding-x-sm;
	}
}