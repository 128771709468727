.filters {
	position: fixed;
	top: rem-calc(70);
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 900;
	pointer-events: none;
	transition: none;

	#gridFilters {
		background-color: white;
	}

	@include media-breakpoint-down(lg) {
		overflow-y: auto;
	    overflow-x: hidden;
	}

	@include media-breakpoint-up(lg) {
		background-color: transparent;
	}

	.toggle-bg {
		display: none;
	}

	.container {

		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}

	@include media-breakpoint-up(xl) {
		position: relative;
		top: 0;

		&.collapse:not(.show) {
			display: block;
			top: auto;
		}
	}

	&.buttons {

		.bottom {
			text-align: left;

			@include media-breakpoint-down(lg) {
				text-align: center;
			}
		}

		@include media-breakpoint-up(xl) {

			&.collapse:not(.show) {
				display: flex;
				top: auto;
				padding-bottom: rem-calc(14);
			}
		}
	}

	@include media-breakpoint-up(xl) {
		overflow-y: none;
		pointer-events: all;
	}

	&.show {
		pointer-events: all;
		background-color: white;

		@include media-breakpoint-up(md) {
			background: transparent;
		}
	}

	.control {
		display: inline-block;
		padding: rem-calc(5);

		i {
			font-size: rem-calc(27);
		}
	}

	.widget {

		@include media-breakpoint-down(lg) {
			padding: 0;
		}
	}

	.container {

		@include media-breakpoint-down(lg) {
			padding: 0;
			max-width: none;
		}
	}

	.box {
		background: white;
		padding: rem-calc(35 35 20 35);
		position: relative;

		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		.top {
			padding: rem-calc(15 6 0 6);
			position: fixed;
			top: rem-calc(64);
			left: 0;
			right: 0;
			z-index: 2001;
			background-color: white;

			@include media-breakpoint-up(xl) {
				display: none;
			}

			.control {
				margin-right: rem-calc(8);
			}
		}

		.bottom {
			padding: rem-calc(35 0);

			@include media-breakpoint-up(xl) {
				padding: 0;
			}
		}
	}
}

.mobile-filters {
	background-color: $gray-100;
	box-shadow: 0 0 10px rgba($gray-700, 0.1);
	padding: rem-calc(9 0);
	top: rem-calc(70);
	bottom: auto;
	height: rem-calc(60);
	z-index: 1;
	transition: all 0.3s;

	&.scrolling-down {
		transform: translateY(-60px);
	}
}

.tab-pane {

	.mobile-filters {
		box-shadow: none;
	}
}

.active-filters {
	@include media-breakpoint-down(lg) {
		display:none;
	}
}

.filters-menu {
	position: fixed;
	top: rem-calc(70);
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 800;

	.control {
		display: inline-block;
		padding: rem-calc(5);

		i {
			font-size: rem-calc(27);
		}

		&.back {

			i {

				&:before {
					margin: 0;
					position: relative;
					left: rem-calc(-5);
				}
			}
		}
	}
}

.modal-filters-menu {

	.control {
		display: inline-block;
		padding: rem-calc(5);
		color: $headings-color;
		cursor: pointer;

		i {
			font-size: rem-calc(27);
		}

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
}

.modal-filters {
	position: absolute;
	z-index: 100;
	background: white;
	left: 0;
	right: 0;
	top: 0;
	box-shadow: 0 0 10px rgba($gray-700, 0.1);
	padding: rem-calc(18);

	@include media-breakpoint-up(lg) {
		position: relative;
		left: auto;
		right: auto;
		top: auto;
		box-shadow: none;
		padding: 0;
	}

	.control {
		display: inline-block;
		padding: rem-calc(5);
		color: $headings-color;
		cursor: pointer;

		i {
			font-size: rem-calc(27);
		}

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&.collapse:not(.show) {

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}
}

#controlButtons {

	.container {

		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}
}