.notifications {

	.rgPager .rgPagerCell a.rgCurrentPage {
		margin: 0.45rem 0.25rem;
	}
}

.notification {
	border: 1px solid $gray-200;
	border-left: 8px solid $primary;
	padding-right: 5px;

	a {
		color: $body-color;
	}

	.icon-chevron-right {
		color: $gray-300;
	}
}