$hamburger-layer-color: white;

$h1-font-size-sm: rem-calc(30);
$h2-font-size-sm: rem-calc(25);
$h3-font-size-sm: rem-calc(20);
$h4-font-size-sm: rem-calc(18);
$h5-font-size-sm: rem-calc(16);
$h6-font-size-sm: rem-calc(14);

// z-index variables

$layer-base: 0;
$layer-1: 1;
$layer-2: 2;
$layer-3: 3;