.footer {
	margin-top: rem-calc(35);

	@include media-breakpoint-up(md) {
		margin-top: rem-calc(35);
	}

	&.in-tab {
		margin-top: rem-calc(30);
		
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		.widget {

			@include media-breakpoint-up(md) {
				padding-top: rem-calc(100);
			}

			@include media-breakpoint-down(md) {
				background-color: transparent;
				padding: 0;
			}

			.widget-content {

				@include media-breakpoint-down(md) {
					padding: 0;
				}
			}
		}
	}
}