.mobile-nav {
	padding: rem-calc(0 7);

	.mobile-padd {
		padding: rem-calc(0 8);

		&:nth-child(odd) {
			
		}

		&:nth-child(even) {
			
		}
	}
}