table {

    th {
        font-weight: $font-weight-bold;
        color: $headings-color;

        &.date-header {
            text-align: center;
        }
    }
    
    tbody {

        tr {

            &.disabled {
                background-color: $body-bg;
                //color: rgba($body-color, 0.8);
            }

            &:hover {
                cursor: pointer;

                td {
                    background-color: rgba($electric-green, 0.05);
                }
            }
        }
    }

    tr {

        &.redRow, &.redrow, &.danger {
            background-color: rgba($danger, 0.14);
        }

        &.orangeRow, &.orangerow, &.warning {
            background-color: rgba($warning, 0.18);
        }

        &.disabled {

            &.redRow, &.redrow, &.danger {
                background-color: rgba($danger, 0.1);
            }

            &.orangeRow, &.orangerow, &.warning {
                background-color: rgba($warning, 0.08);
            }
        }
    }

    .rgEditForm {
        margin: rem-calc(0 -7);
    }

    &.edit-table {

        tr {

            &:hover {

                td {
                    background-color: white;
                }
            }
        }

        td {
            padding: 0;
            border: none;
        }
    }

    .in-table-widget {

        .widget-content {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.table {
    width: 100% !important;
    max-width: 100%;

    &.no-first-col {

        td, th {

            &:nth-child(2) {
                border-left: none;
            }
        }
    }

    &.inline-checkbox-wrapper {

        .custom-checkbox {
            display: inline;
        }
    }

    &.schedule-wo {

        &.vertical {

            tr {

                td {
                    border-left: 1px solid $table-border-color;
                    border-right: 1px solid $table-border-color;

                    &:first-child {
                        border-left: 0;
                    }

                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }

        &.no-vertical {
            border-collapse: collapse; 

            tbody {

                tr {

                    td {
                        border-left: none;
                        border-right: none;
                    }
                }
            }
        }

        .rgEditForm {
            margin: 0;

            table {

                td {
                    border: none;
                }

                .wo-dropdowns-wrapper {

                    td {
                        padding: 0;

                        .dropdown-toggle {
                            min-height: rem-calc(47);
                        }
                    }
                }
            }
        }

        tr {

            &:not(.rgRow), &:not(.rgEditRow) {

                td[colspan='16'] {
                    padding: 0;
                }
            }
        }
    }

    tr {

        &:hover {

            .table-inner {

                tr {

                    td {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    
    &:focus {
        outline: none;
    }

    &.responsive-md {

        @include media-breakpoint-down(md) {
            transition: all 0.3s;
            overflow-x: auto;
        }
    }

    &.responsive {
        transition: all 0.3s;
        overflow-x: auto;

        colgroup {

            col {

                @include media-breakpoint-down(xl) {
                    width: auto !important;
                }
            }
        }
    }

    th, td {
        padding: rem-calc(9);

        @include media-breakpoint-up(xl) {
            padding: rem-calc(5 10);
        }
    }

    input {

        &:focus {
            outline: none;
        }
    }

    thead {

        th {
            font-weight: $font-weight-bolder;
            padding: $table-cell-padding $table-cell-padding $table-cell-padding $table-cell-padding;
            color: $dark-navy;

            a:not(.btn) {
                color: $table-head-color;
            }

            &.rgSorted {

                .rgSortAsc {
                    background-image: url('../Assets/icons/arrow-up.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: transparent;
                    border: none;
                    background-size: rem-calc(12);
                }

                .rgSortDesc {
                    background-image: url('../Assets/icons/arrow-down.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: transparent;
                    border: none;
                    background-size: rem-calc(12);
                }
            }
        }
    }

    .rgPager {

        thead {
            display: none;
        }

        .rgPagerCell {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            border: none;
            padding: 0;

            .rgInfoPart {
                margin-right: rem-calc(12);
            }

            span {

                span {
                    display: inline-block;
                    width: rem-calc(35);
                    text-align: center;
                }
            }

            a {
                width: rem-calc(40);
                height: rem-calc(40);
                border-radius: $border-radius;
                background-color: $gray-100;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $gray-700;
                margin: rem-calc(0 4);
                transition: background-color 0.2s;
                text-decoration: none;

                &:hover {
                    background-color: darken($gray-100, 5%);
                }

                &.rgCurrentPage {
                    background-color: $primary;
                    color: white;
                }
            }

            span.rgCurrentPage {
                width: rem-calc(40);
                height: rem-calc(40);
                border-radius: $border-radius;
                background-color: $gray-100;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $gray-700;
                margin: rem-calc(0 4);
                transition: background-color 0.2s;
                text-decoration: none;
                background-color: $primary;
                color: white;

                &:hover {
                    background-color: darken($gray-100, 5%);
                }
            }
        }
    }

    tr {

        .btn-delete {
            opacity: 0;
            transition: opacity 0.1s;
        }

        &:hover {

            .btn-delete {
                opacity: 1;
            }
        }

        @media (hover: none) {

            .btn-delete {
                opacity: 1;
            }
        }

        &.success {

            td {
                background-color: rgba($electric-green, 0.05);
            }

            .status {
                color: $electric-green;
                font-weight: $font-weight-bold;
            }

            &:hover {

                td {
                    background-color: rgba($electric-green, 0.075);
                }
            }
        }

        &.redRow {

            td {
                background-color: rgba($danger, 0.06);
            }

            .status {
                color: $body-color;
                font-weight: $font-weight-normal;
            }

            &:hover {

                td {
                    background-color: rgba($danger, 0.075);
                }
            }
        }
    }
}

table.listPager {
    background-color: transparent;
}

.tab-content {

    .tab-pane-mobile {

        @include media-breakpoint-down(lg) {
            display: block;
            opacity: 1;
        }
    }
}

.table-bordered {
    border-left: none;
    border-right: none;
    border-bottom: none;

    th {
    	color: $table-head-color;
    }

    td, th {

    	&:first-of-type {
    		border-left: none;
    	}

    	&:last-of-type {
    		border-right: none;
    	}
    }

    td {

        .linkCell {

            div {

                .info {
                    margin-right: rem-calc(10);
                }

                &:not(.custom-control) {
                    display: flex;
                    justify-content: end;
                }
            }
        }
    }

    tr {

    	&:last-of-type {

    		td {
    			border-bottom: none;
    		}
    	}
    }
}

.detail-table, .swms-table {

    tbody {

        tr {

            &:hover {

                td {
                    background-color: white;
                }
            }
        }
    }
}

.rgEditRow + tr {

    td {

        &:hover {
            background-color: transparent;
        }
    }
}

.divPOItemTotals {

    table {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 70%;
            border-spacing: rem-calc(10); 
            border-collapse: separate; 
            margin-left: auto; 
            margin-right: auto;
        }
    }
}

.show-hide-columns-xl {
    display: none;

    @include media-breakpoint-up(xl) {
        display: table-cell;
    }

    &.collapse:not(.show) {

        @include media-breakpoint-up(xl) {
            display: table-cell;
        }
    }

    &.collapse.show {
        display: table-cell;
    }
}

.column-filter {

    &.dropdown {

        .dropdown-toggle {
            padding-right: rem-calc(30);

            &:after {
                color: white;
                top: rem-calc(1);
                right: rem-calc(7);
            }
        }

        .dropdown-item {
            padding-left: rem-calc(15);

            &:before {
                content: '';
                width: rem-calc(25);
                display: inline-block;
            }

            &[aria-expanded="true"] {

                &:before {
                    content: '\e8a6';
                    font-family: fontello;
                }
            }
        }

        .dropdown-menu {
            box-shadow: rem-calc(0 0 10) rgba($dark, 0.3);
        }
    }
}

.stock-items {

    .edit-table {

        .custom-control {
            padding-right: rem-calc(12);
        }
    }
}

.Gap td {
    border-top: 20px solid $gray-200 !important;
}

table {

    .no-hover {

        tbody {

            tr {

                &:hover {

                    td {
                        background-color: white;
                    }
                } 
            }
        }
    }
}

.table-striped {

    tbody {

        tr {



            .form-control {
                border: 1px solid rgba($dark-navy, 0.1);
            }


        }
    }
}

.table-auto {

    table {
        table-layout: auto !important;
    }
}