.time-picker {

	&.alt {

		td {

			.rcTimePopup {
				border: none;
				background-color: transparent;
				width: rem-calc(43);
			}
		}
	}

	table {
		width: 100%;
	}

	td {
		padding: 0;
		border: none;

		.rcTimePopup {
			position: absolute;
			top: 0;
			right: rem-calc(28);
			background-image: url(../Assets/icons/clock-simple.svg) !important;
			background-position: 0;
			background-size: 1.25rem;
			transition: all 0.2s;
			height: 100%;
			width: rem-calc(35);
			background-position: center;
			
		    &:hover, &:focus {
		    	background-position: center !important;
		    	opacity: 0.6;
		    }
		}
	}

	.picker-button {
		width: 42px;
	}
}

.RadCalendarTimeView {
	font-family: $font-family-sans-serif !important;
	border: none !important;
}

.RadCalendarTimeView_Simple {
	box-shadow: rem-calc(0 3 10) rgba($body-color, 0.5);

	th {
		border: none !important;
		background-color: $gray-100 !important;
		padding: rem-calc(10 0) !important;
		font-size: $font-size-sm !important;
		font-weight: $font-weight-bold !important;
		color: $dark-navy !important;
	}
}

table {

	&.RadCalendarTimeView_Simple {
		font-family: $font-family-sans-serif !important;

		tr {

			&:hover {

				td {
					background-color: transparent;
				}
			}
		}

		td {
			border: none !important;
			font-size: $font-size-sm;

			&.rcHover {

				a {
					border: none !important;
					background-color: $gray-100 !important;
					color: $black !important;
				}
			}

			&.rcSelected {

				a {
					border: none !important;
					background-color: $body-color !important;
					color: white !important;
				}
			}
		}

		a {
			color: $body-color;
			padding: rem-calc(5 9) !important;
		}
	}
}

.RadTimePicker {
	width: 100% !important;

	&.w-auto {
		width: auto !important;
		flex: 1;
	}

	.rcTable {

		tbody {

			td {
				position: relative;
			}
		}
	}
}