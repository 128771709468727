#divMasterOverlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    background-color: rgba($white, 0.9);
}