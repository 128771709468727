.matrix {

	td, th {
		padding: rem-calc(10);
		text-align: right;

		.form-control {
			height: rem-calc(35);
			width: rem-calc(80);
			display: inline;
			text-align: right;

			&.edited {
				color: $secondary;
			}
		}
	}

	th {

		&:first-child {
			text-align: left;
		}
	}

	td {

		&.tdTitle {
			text-align: left;
		}
	}
}