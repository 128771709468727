.innerThreat {
    margin-left: auto;
    margin-right: auto;
    padding: rem-calc(30);
    text-align: center;
    color: $body-color;

    .threatGroup {

        div {
            opacity: 0.4;
            cursor: pointer;
            
            img {
                width: 100%;
                padding: rem-calc(5);
            }

            &.active {
                opacity: 1;
            }
        }        
    }

    .Low {
        color: $success;
    }

    .Medium {
        color: $warning;
    }

    .High {
        color: $danger;
    }

    .risk {

        span {
            display: block;
            font-size: rem-calc(20);
        }

        img {
            max-width: rem-calc(100);
        }
    }

    .Summary {

        .ppe {

            img {
                margin: rem-calc(0 5);
            }
        }
    }
}

.clickPanel {

    .active {
        border: #{rem-calc(3)} solid $secondary;
    }
}



.innerThreat .threatGroup div.active {
    opacity: 1;
}









#divStartSafetyWizard .inner {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    background: #eee;
    padding: 30px;
    text-align: center;
    color: #555;
    /*whatever width you want*/
}



.Low {
    color: rgb(32, 165, 98);
}

.Medium {
    color: rgb(247, 148, 29);
}

.High {
    color: rgb(216, 37, 33);
}

.clickPanelContainer {

    @include media-breakpoint-up(md) {
        margin: rem-calc(0 -5);
    }

    .clickPanel {
        border: 3px solid transparent;

        @include media-breakpoint-up(md) {
            margin: rem-calc(5 0);
        }

        &.active {

            .card {
                font-weight: $font-weight-bold;
                border: 3px solid $secondary;
            }
        }

        .card {
            position: relative;
            border: 3px solid transparent;
            font-size: $font-size-md;

            &:hover {
                cursor: pointer;
            }

            @include media-breakpoint-up(md) {

                &:before {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }

                .card-body {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.btnpadd {
    margin-right: 5px;
}

.RadGrid_Simple .rgMasterTable, .RadGrid_Simple .rgDetailTable, .RadGrid_Simple .rgGroupPanel table, .RadGrid_Simple .rgCommandRow table, .RadGrid_Simple .rgEditForm table, .RadGrid_Simple .rgPager table {
    font-size: 13px;
}

input[title="Expand"] {
    height: 20px;
}

input[title="Collapse"] {
    height: 20px;
}

/*.rgAdd
{ 
  background:url('/img/image1.jpg') center center no-repeat !important;      
}*/

.table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {
    /*background-color: none !important;*/
    background-color: transparent !important;
}

.GridItemDropIndicator {
    background-color: #2aabd2;
}

.GridDraggedRows {
    background-color: #9ee3ff !important;
    border: none !important;
}

    .GridDraggedRows td {
        border: 1px solid #DDD !important;
    }