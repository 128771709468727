#schedule {

    &.client {
        width: 100%;
        border-collapse: collapse;

        .grey {
            color: #aaa;
        }

        .black {

            td {

                &.week {
                    background: #bbb !important;
                }

                &.worst {
                    background: #bbb !important;
                }
            }
        }

        .purple, .label-purple {
            color: #b100ff;
        }

        .ui-draggable-dragging {
            opacity: 0.6 !important;
        }

        .btnAdd {
            padding-left: 0px;
            margin-top: -5px;
        }

        .assistant {
            background: #FFF;
            padding: 5px;
            margin: 5px;
            margin-left: 0;
            cursor: move;
            color: black;

            &:hover {
                outline: 3px solid #233360;
                border-radius: 3px;
            }
        }

        .noAssist {
            background-color: #bbb !important;
        }

        .hasAssistant {
            padding: 0px 5px;

            &:hover {
                border: 1px solid red;
                color: white;
                background-color: #bbb;
            }
        }

        .hasAssistantInfo {
            padding: 0px 5px;

            &:hover {
                border: 1px solid red;
                color: white;
                background-color: #bbb;
            }
        }

        .hoverAssistant {
            border: 1px solid red !important;
            color: white !important;
            background-color: #bbb !important;
        }

        .woonsite {
            border: 2px solid green !important;
        }

        #contentPlaceHolder_lblWeek {
            position: relative;
            top: 3px;
        }

        #sortName {

            .icon-chevron-up, .icon-chevron-down {
                float: none;
                padding: 4px;
                cursor: pointer;
                color: #666;

                &:hover {
                    background: #233360;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        #sortUtilisation, #sortWeeklyUtilisation {
            cursor: pointer;

            .icon-chevron-up, .icon-chevron-down {
                float: none;
                padding: 4px;
                cursor: pointer;
                color: #666;

                &:hover {
                    background: #233360;
                    color: #fff;
                }
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;

            thead {

                td {
                    background: $gray-200;
                    font-weight: $font-weight-bold;
                    border-left: none;
                }
            }

            tr {

                &:first-child {

                    td {
                        border-top: none;
                    }
                }

                .week {

                    &.active {

                        .assignedInfo {
                            -webkit-transition-delay: .5s; /* Safari */
                            transition-delay: .5s;
                            opacity: 1;
                            transition: opacity .5s ease-in-out;
                            -moz-transition: opacity .5s ease-in-out;
                            -webkit-transition: opacity .5s ease-in-out;
                            color: #b100ff;
                        }

                        .contextInfo {
                            -webkit-transition-delay: .5s; /* Safari */
                            transition-delay: .5s;
                            opacity: 1;
                            transition: opacity .5s ease-in-out;
                            -moz-transition: opacity .5s ease-in-out;
                            -webkit-transition: opacity .5s ease-in-out;
                        }
                    }

                    &.inactive {

                        .contextInfo, .assignedInfo {
                            opacity: 0;
                        }

                        .contextInfo {
                            -webkit-transition-delay: .5s; /* Safari */
                            transition-delay: .5s;
                            opacity: 1;
                            transition: opacity .5s ease-in-out;
                            -moz-transition: opacity .5s ease-in-out;
                            -webkit-transition: opacity .5s ease-in-out;
                        }

                        .assignedInfo {
                            -webkit-transition-delay: .5s; /* Safari */
                            transition-delay: .5s;
                            opacity: 1;
                            transition: opacity .5s ease-in-out;
                            -moz-transition: opacity .5s ease-in-out;
                            -webkit-transition: opacity .5s ease-in-out;
                            color: #b100ff;
                        }
                    }

                    &.worst {
                        background: #FCC !important;

                        &.black {
                            background: #bbb !important;
                        }
                    }

                    &.hover {
                        outline: 2px solid #233360;
                    }

                    &.black {
                        background: #bbb !important;
                    }

                    .assignedInfo {
                        opacity: 0;
                    }

                    .contextInfo {
                        opacity: 0;
                    }
                }

                &.best {

                    td {

                        &.tech {
                            background: #BFB;
                        }
                    }

                    .week {

                        &.active {
                            background: #BFB;
                        }
                    }
                }

                &.area {

                    .week {

                        &.active {
                            background: #EFE;
                        }
                    }
                }
            }

            td {
                border: 1px solid $table-border-color;
                padding: 5px;
                vertical-align: top;
                padding: rem-calc(9);

                &.week {
                    padding: rem-calc(9);
                    cursor: default;

                    &.over {

                        span {

                            &.hours {
                               color: red; 
                            }
                        }
                    }
                }

                &.tech {
                    background: $gray-200;
                    border-left: none;

                    .icon-chevron-up, .icon-chevron-down {
                        float: right;
                        padding: 4px;
                        cursor: pointer;
                        color: #666;

                        &:hover {
                            background: #233360;
                            color: #fff;
                        }
                    }
                }

                &.space {
                    border: 0;
                    background: white;
                    text-align: right;
                    vertical-align: bottom;
                }

                .job {
                    background: white;
                    padding: rem-calc(15);
                    margin: 5px;
                    margin-left: 0;
                    cursor: move;
                    border-radius: rem-calc(5);
                    border: 1px dotted #DDD;

                    &:hover {
                        outline: 3px solid darken($gray-200, 10%);
                    }

                    &.wocomplete {
                        background-color: $gray-200;
                    }
                }
            }
        }
    }
}

span {

    &.workareas {
        background: #EFE;
    }

    &.best {
        background: #BFB;
    }

    &.worst {
        background: #FCC !important;
    }

    &.black {
        background: #bbb !important;
    }
}

.jobMatch {
    color: #b100ff;
    border-radius: 3px;
}

#unallocated-wrap {
    background: $gray-200;
    margin-bottom: 5px;
    box-shadow: 0 0 5px darken($gray-200, 5%) inset;
}

#unallocated {
    overflow-x: auto;
    white-space: nowrap;
    padding-left: 5px;
    min-height: 30px;

    .job {
        display: inline-block;
    }

    &.hover, &:hover {
        outline: 1px solid #233360;
    }
}