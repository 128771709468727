.header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: rem-calc(33);
    padding-bottom: rem-calc(15);

    @include media-breakpoint-up(sm) {
        min-height: rem-calc(120);
    }

    @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
        min-height: rem-calc(90);
    }

    .breadcrumbs {
        font-size: $font-size-sm;
        color: $headings-color;
        display: block;
        margin-bottom: rem-calc(15);
        font-weight: $font-weight-bold;

        a {
            color: rgba($body-color, 0.55);
        }

        i {
            color: rgba($body-color, 0.25);
        }

        span {
            display: inline;
        }
    }

    h1 {
        margin: 0;

        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
            padding: rem-calc(18 0);
        }

        a {
            color: $secondary;
        }

        span {
            display: block;
            color: $dark-navy;
        }

        .alert {
            font-size: $font-size-sm;

            @include media-breakpoint-up(md) {
                position: absolute;
                right: 0;
                top: 0;
            }

            @include media-breakpoint-up(lg) {
                top: rem-calc(25);
            }

            @include media-breakpoint-up(xl) {
                right: auto;
            }
            
            &.w-breadcrumb {

                @include media-breakpoint-up(md) {
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    right: 0;
                }

                @include media-breakpoint-up(xl) {
                    position: absolute;
                    top: rem-calc(45);
                    right: auto;
                }
            }

            &.alert-warning {
                color: $orange-alt;

                &:before {
                    content: '\e8a8';
                    font-family: fontello;
                    font-size: rem-calc(15);
                    margin-right: rem-calc(10);
                    color: $orange-alt;
                }     
            }
        }
    }

    .mobile-filters {

        .container {

            @include media-breakpoint-down(xl) {
                padding-left: rem-calc(15);
                padding-right: rem-calc(15);
            }
        }
    }

    .header-top {

        &.with-controls {

            h1 {

                @include media-breakpoint-up(xl) {
                    padding-top: rem-calc(20);
                }
            }
        }

        &.with-controls-lg {

            h1 {

                @include media-breakpoint-up(lg) {
                    padding-bottom: rem-calc(18);
                }
            }
        }
    }

    &.with-filters {
        padding-top: rem-calc(75);
        padding-bottom: rem-calc(15);

        @include media-breakpoint-up(md) {
            padding-top: rem-calc(15);
        }

        @include media-breakpoint-up(xl) {
            padding-top: 0;
            padding-bottom: 0;
        }

        .buttons {

            @include media-breakpoint-up(xl) {
                padding-bottom: rem-calc(14);
            }
        }
    }

    &.with-tabs {

        .header-top {
            padding-bottom: rem-calc(15);

            @include media-breakpoint-up(md) {
                padding-bottom: 0;
            }
        }

        h1 {
            padding-bottom: 0;

            &.inline {
                display: inline-flex;
            }
        }
    }

    &.no-tabs {

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }

        h1 {
            padding-bottom: 0;

            @include media-breakpoint-up(md) {
                padding: 0;
            }
        }

        #controlButtons {

            &.collapse {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &.details {

        @include media-breakpoint-down(md) {
            padding: rem-calc(25 0);
        }
    }

    &.header-lg {
        @include media-breakpoint-down(lg) {
            min-height:0;
            padding-top:0;
        }
    }

    .buttons {
        pointer-events: all;

        &.show-md {

            @include media-breakpoint-up(md) {
                display: inline-flex;
                position: relative;
                top: auto;
                overflow: visible;
                z-index: 300;

                .container {
                    padding: 0;
                }

                .toggle-bg {
                    display: none;
                }

                .widget {
                    background-color: transparent;
                    padding: 0;
                }

                .box {
                    padding: 0;
                    background-color: transparent;

                    .top {
                        display: none;
                    }
                }
            }
        }

        &.show-lg {

            @include media-breakpoint-down(lg) {

                .btn {
                    border-color: $gray-200;
                }
            }

            @include media-breakpoint-up(lg) {
                display: inline-flex;
                position: relative;
                top: auto;
                overflow: visible;

                .container {
                    padding: 0;
                }

                .toggle-bg {
                    display: none;
                }

                .widget {
                    background-color: transparent;
                    padding: 0;
                }

                .box {
                    padding: 0;
                    background-color: transparent;

                    .top {
                        display: none;
                    }
                }
            }
        }

        &.show-xl {

            @include media-breakpoint-down(xl) {

                .btn {
                    border-color: $gray-200;
                }
            }

            @include media-breakpoint-up(xl) {
                display: inline-flex;
                position: relative;
                top: auto;
                overflow: visible;

                .container {
                    padding: 0;
                }

                .toggle-bg {
                    display: none;
                }

                .widget {
                    background-color: transparent;
                    padding: 0;
                }

                .box {
                    padding: 0;
                    background-color: transparent;

                    .top {
                        display: none;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        @include media-breakpoint-up(xl) {
            display: inline-flex;
        }
    }
}

.inner-header {
    padding-bottom: 0;

    @include media-breakpoint-down(md) {
        background-color: transparent;
        padding: 0;
        margin: rem-calc(15 0 10 0);
    }

    .form-control {

        @include media-breakpoint-down(md) {
            background-color: white;
        }
    }
}