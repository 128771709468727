.toast-message {
	position: fixed;
	bottom: 0;
	z-index: 1000;
	left: 0;
	right: 0;
	margin: rem-calc(0 27);
	bottom: rem-calc(25);
	box-shadow: rem-calc(0 6 10) rgba($gray-700, 0.32);

	@include media-breakpoint-up(md) {
		margin: rem-calc(0 40);
	}

	@include media-breakpoint-up(lg) {
		position: relative;
		margin: rem-calc(20 0 0 0);
		bottom: auto;
		box-shadow: none;
	}

	&.alert-success {

		&:before {
			content: '\e814';
			font-family: fontello;
			margin-right: rem-calc(5);
		}
	}
}