.accordion {

	.card-header {
		padding: 0;
	}

	.card-body {
		padding-top: 0;
	}

	.card-footer {
		background-color: $white;
		border-top: $gray-100 solid 1px;
	}

	.btn {
		text-align: left;
		position: relative;

		&.toggle {
			padding-right: 35px;
			background-color: $gray-500;
			
			&:after {
				position: absolute;
				right: rem-calc(10);
				top: rem-calc(12);
				font-family: fontello;
				content: '\e8c0';
				font-size: rem-calc(18);

			}

			&.collapsed {
				background-color: white;
				
				&:after {
					font-family: fontello;
					content: '\e8bf';
				}
			}
		}

		&:focus {
			box-shadow: none;
		}
	}

	.card {
		margin-bottom: 20px;

		.step {
			border-top: $gray-100 solid 1px;
			margin-bottom: 20px;
			padding-bottom: 20px;
			padding-top: 20px;

			&:last-of-type {
				margin-bottom: 0;
			}

			&:first-of-type {
				border-top: none;
			}
		}
	}

	.listing-wrapper {
		padding: rem-calc(10 0);
	}

	.step-container {
		padding: rem-calc(10 20);
	}

	&.checkbox {

		.card-header {

			h5 {
				margin: 0;
				position: relative;
			}

			.custom-control {
				position: absolute;
				top: 0;
				bottom: 0;
				margin-left: rem-calc(15);
				line-height: 1.5;
				display: flex;
				align-items: center;
			}

			.btn {
				font-size: inherit;
				position: relative;
				padding-left: rem-calc(55);
				width: 100%;

				&:after {
					content: '\e8bf';
					font-family: fontello;
					color: $body-color;
					position: absolute;
					right: rem-calc(12);
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
				}

				&[aria-expanded="true"] {
					background-color: $primary;
					color: white;

					&:after {
						color: white;
						content: '\e8c0';
					}
				}
			}
		}
	}
}