$dark-navy: #021F49;
$butterfields-blue: #0546A5;
$electric-blue-alt: #1796D8;
$electric-blue: #36C4F0;
$soft-blue: #9CDEF5;
$soft-green: #60E3C5;
$soft-green-alt: #ABE2C6;
$soft-green-alt-2: #AFEF97;
$electric-green: #00D189;
$pink-lilac: #CB98F0;
$soft-pink-lilac: #E0C8DD;
$soft-orange: #F0B564;
$orange: #FF9D00;
$salmon: #FF8181;
$red: #EA0036;
$yellow: #FFCE00;
$white: #FFF;
$orange-alt: #ea8409;