.error-wrapper {
	background-color: $body-bg;

	h1 {

		@include media-breakpoint-down(md) {
			font-size: rem-calc(28);
		}
	}

	.inner-wrapper {

		@include media-breakpoint-up(lg) {
			padding-top: rem-calc(30);
		}

		@media screen and ( min-height: 900px ) and ( min-width: $grid-breakpoint-lg ) {
			min-height: 100vh;
			padding-top: 0;
			position: fixed;
			left: 0;
			right: 0;
		}

		.box-wrapper {

			@media screen and ( min-height: 900px ) and ( min-width: $grid-breakpoint-lg ) {
				padding-bottom: 160px;
			}

			@include media-breakpoint-up(lg) {
				min-height: rem-calc(800);
			}
		}

		.graphic {
			padding: rem-calc(25 0);
			height: rem-calc(200);

			@include media-breakpoint-up(lg) {
				padding-top: rem-calc(30);
				height: rem-calc(380);
			}
		}
	}

	.message-bg {
		background-color: rgba($gray-700, 0.07);
		align-items: center;
		justify-content: center;

		.inner {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;

			div {
				padding: rem-calc(45 15 30 15);

				@include media-breakpoint-up(md) {
					padding: rem-calc(75 65);
				}

				@include media-breakpoint-up(lg) {
					padding: rem-calc(0 65);
				}
			}
		}
	}

	.form-wrapper {

		.inner {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;
			padding: 0;

			@include media-breakpoint-up(md) {
				padding: rem-calc(50 0);
			}

			.inner-wrap {
				padding: rem-calc(30 15);

				@include media-breakpoint-up(md) {
					padding: rem-calc(65);
				}

				@include media-breakpoint-up(lg) {
					padding: rem-calc(0 15);
				}
			}
		}
	}

	.login-form {

		.inner {
			padding: rem-calc(50 20);

			@include media-breakpoint-up(lg) {
				padding: rem-calc(115 20);
			}
		}
	}

	.footer {
		padding: rem-calc(20 0);
		margin-top: rem-calc(15);

		@include media-breakpoint-up(lg) {
			color: rgba($gray-100, 0.5);
		}

		@media screen and ( min-height: 900px ) {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			margin-top: rem-calc(50);
		}

		a {
			
			@include media-breakpoint-up(lg) {
				color: rgba($gray-100, 0.5);
			}
		}
	}
}