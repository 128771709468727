.card {

	.card-header {
		background-color: $gray-100;
	}

	.padding {
		padding: rem-calc(20);
	}

	&.info {
		border-left: 3px solid $secondary;
	}

	&.default {
		border-left: 3px solid $gray-200;
	}

	&.completed {
		border-left: 3px solid $success;
	}

	&.bordered {
		border: 1px solid $gray-300;
	}

	&.restricted {
		border-left: 3px solid $danger;

		&:after {
			content: '\e822';
			font-family: fontello;
			position: absolute;
			top: rem-calc(7);
			right: rem-calc(14);
			color: $gray-300;
		}
	}

	&.inner-table {
		border: 1px solid $table-border-color;

		.card-body {
			background-color: white;

			tbody {

				tr {

					&:hover {

						td {
							background-color: transparent;
						}
					}
				}
			}
		}

		.card-footer {
			background-color: white;
		}
	}

	&.danger {
		border-left-color: $danger;
	}

	&.redRow {
		border: rgba($danger, 0.3) 1px solid;
		position: relative;
		background-color: white;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba($danger, 0.06);
		}

		&:after {
			content: 'Overdue';
			position: absolute;
			top: rem-calc(-10);
			left: rem-calc(25);
			background-color: $danger;
			color: white;
			border-radius: $border-radius;
			padding: rem-calc(2 10);
			text-transform: uppercase;
			font-weight: 600;
			font-size: 10px;
		}

		&.plain {

			&:after {
				content: '';
				display: none;
			}
		}
	}

	&.orangeRow, &.orange {
		border: rgba($warning, 0.3) 1px solid;
		position: relative;
		background-color: white;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba($warning, 0.18);
		}

		&:after {
			content: 'Approaching Due Date';
			position: absolute;
			top: rem-calc(-10);
			left: rem-calc(25);
			background-color: $warning;
			color: white;
			border-radius: $border-radius;
			padding: rem-calc(2 10);
			text-transform: uppercase;
			font-weight: 600;
			font-size: 10px;
		}

		&.disabled {
			border: rgba($warning, 0.3) 1px solid;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background-color: rgba($warning, 0.08);
			}
		}
	}

	&.orange {

		&:after {
			display: none;
		}
	}

	&.nav-item {
		padding: rem-calc(35 30);
		flex-direction: row;
		align-items: center;

		h2 {
			color: $secondary;
			font-size: rem-calc(20);
			margin: 0;
		}

		.number {
			padding-right: rem-calc(10);

			label {
				color: rgba($body-color, 0.7);
				margin: 0;
				font-weight: $font-weight-normal;
				font-size: $font-size-lg;
			}
		}
	}

	&.right-arrow {
		position: relative;
		
		&:after {
			content: '\e8be';
			font-family: fontello;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;
		    width: rem-calc(25);
		    opacity: 0.3;
		}
	}

	&.card-md {
		padding: rem-calc(20 35 20 20);
		height: rem-calc(80);

		h2 {

			@include media-breakpoint-down(xs) {
				font-size: 5vw;
			}
		}
	}

	&.bordered {
		border: 1px solid $gray-200;
		padding: rem-calc(15);
	}

	&.disabled {
		background-color: rgba($white, 0.5);
		color: rgba($body-color, 0.7);
	}
}

.notes {

	.card {
		border-top: 1px solid $gray-200;
		border-bottom: 1px solid $gray-200;
		border-right: 1px solid $gray-200;
	}
}