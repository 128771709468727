.modal {

	.modal-header {

		.modal-title {
			font-size: rem-calc(24);
		}

		.close {
			padding: rem-calc(22 16);
			color: $body-color;
		}
	}

	.modal-dialog {

		&.modal-xxl {
			max-width: rem-calc(1340);
		}
	}

	&.confirm-popup {
		z-index: 1060;
		white-space: pre-wrap;

		&:not([aria-hidden="true"]) {

			+ .modal-backdrop {
				z-index: 1050;
			}
		}
	}
}

.modal-backdrop {

	&:nth-child(2) {
		z-index: 1050;
	}

	~ .modal-backdrop {
		z-index: 1050;
	}
}

// #popupSafteyAddSWMS {

// 	.modal-body {

// 		@include media-breakpoint-down(md) {
// 			padding-bottom: 140px;
// 		}
// 	}

// 	.modal-footer {

// 		@include media-breakpoint-down(sm) {
// 			width: auto;
// 			left: 0;
// 			right: 0;
// 		}

// 		@include media-breakpoint-down(md) {
// 			position: fixed;
// 			bottom: 0;
// 			background: #fff;
// 			z-index: 1060;
// 			max-width: 500px;
// 			width: 100%;
// 		}
// 	}
// }

#popupSafteyAddSWMS {

	.modal-dialog {

		@include media-breakpoint-up(xl) {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
		}

		.modal-content {

			@include media-breakpoint-up(xl) {
				height: 100%;
			}

			.modal-body {

				@include media-breakpoint-up(xl) {
					overflow: auto;
				}
			}
		}
	}
}