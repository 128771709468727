label {
	font-weight: $font-weight-bold;
	color: $dark-navy;
}

select {
    background: url('../Assets/images/arrow-down.svg') no-repeat right #{rem-calc(17)} center/#{rem-calc(8 10)};
    appearance: none;
}

.custom-file {


	.custom-file-label {
		white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;

	    &.no-browse {

	    	&:after {
	    		content: '';
	    		display: none;
	    	}
	    }
	}
}

.single-checkbox {

	.custom-checkbox {

		.custom-control-label {

			span {
				display: none;
			}
		}
	}
}

.custom-checkbox {

	&.checkbox-white {

		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $primary;
		}

		.custom-control-label {

			&::before {
				background-color: white;
			}
		}
	}

	.custom-control-label {

		span {
			padding-left: rem-calc(15);
			display: inline-block;

			span {
				padding-left: 0;
			}
		}

		&::before {
			width: rem-calc(25);
			height: rem-calc(25);
			top: 0;
			border-color: $gray-300;
		}

		&::after {
			width: rem-calc(25);
			height: rem-calc(25);
			top: 0;
		}
	}

	.custom-control-input:checked ~ .custom-control-label::after {
	    background-image: url('../Assets/icons/tick-white.svg');
	}

	&.white {

		.custom-control-label {

			span {
				color: $gray-700;
			    font-weight: $font-weight-normal;
			    display: block;
			}

			&::before {
				background-color: white;
			}
		}

		.custom-control-input:checked ~ .custom-control-label::after {
		    background-image: url('../Assets/icons/tick-dark.svg');
		    border-color: white;
		}
	}

	&.mw-150 {

		.custom-control-label span {
			min-width: rem-calc(150);
		}
	}

	&.outline {

		.custom-control-label {

			&::before {
				border-color: $gray-300;
				background-color: white;
			}
		}

		.custom-control-input {

			&:checked ~ .custom-control-label::before {
				background-color: $primary;
			}
		}
	}
}

.inline-checkbox {

	.checkbox {
		display: flex;
	}

	.custom-checkbox {

		.custom-control-label {
			padding-left: 0;
		}
	}

	label {
		padding-left: rem-calc(10);
	}
}

.form-control {

	&.white {
		background-color: white;
		border-color: $gray-500;
	}

	&:disabled {
		//color: rgba($body-color, 0.4); 
	}
}

.input-group {

	> .input-group-append {

		&:not(:last-child) {
			
			> .btn {

			}
		}
	}
}

.input-group {

	> .input-group-append {

		&:last-child {

			> .btn {

				&:not(:last-child):not(.dropdown-toggle) {
					border-top-right-radius: $btn-border-radius;
					border-bottom-right-radius: $btn-border-radius;
				}
			}
		}
	}
}

.form-control {

	&.white-sm {

		@include media-breakpoint-down(md) {
			background-color: white;
		}
	}
}

.form-group {

	.stretch-content {

		.RadInput {
			flex: 1;
		}
	}
}

.rbToggleButton {

	&.btn-primary, &.btn-warning {

		.rbText {
			color: white;
			padding-left: 0 !important;
		}
	}

	.rbPrimaryIcon {
		width: rem-calc(25);
		height: rem-calc(25);
		background-color: $gray-100;
		background-image: none;
		border-radius: $border-radius;

		&.rbToggleCheckboxFilled {
			background-color: $primary;
		}

		&.rbToggleCheckboxChecked {

			&:before {
				content: '';
				background-color: $primary;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}

			&:after {
				background-image: url(../Assets/icons/tick-white.svg);
				background-size: rem-calc(13);
				background-repeat: no-repeat;
				background-position: center center;
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}

		&.rbToggleCheckbox {
			background-image: none !important;
			border: 1px solid rgba($gray-700, 0.5);
			background-color: transparent;
		}
	}

	.rbText {
		padding-left: rem-calc(46) !important;
	    line-height: rem-calc(24) ;
	    font-weight: $font-weight-bold;
	    color: $dark-navy;
	    font-size: rem-calc(13);
	    font-family: $font-family-sans-serif !important;
	}

	&.rbHovered {
		background-color: transparent !important;
		border-color: transparent !important;
		
	}
}

.RadButton_Simple .rbToggleCheckboxFilled {
	background-image: none !important;
}

.riSingle {

	.riTextBox {

		&.form-control-sm {
			height: $input-height-sm !important;	
		}
	}
}