.nav-pills {

	&.padding-ipad-sm {

		.nav-item {

			.nav-link {

				@include media-breakpoint-down(lg) {
					margin: rem-calc(0 16);
				}
			}
		}
	}

	.nav-item {
		margin: 0;
		text-align: center;

		.nav-link {
			margin: rem-calc(0 25);
		}

		&:first-of-type {

			.nav-link {
				margin-left: 0;
			}
		}

		.nav-link {
			color: $gray-700;
			padding: $nav-link-padding-y $nav-link-padding-x;
			border-bottom: #{rem-calc(4)} solid transparent;

			&.active {
				background-color: transparent;
				border-bottom: #{rem-calc(4)} solid $nav-pills-link-active-bg;
				color: $dark-navy;
				font-weight: $font-weight-bold;
			}
		}
	}

	&.vertical-pills {

		.nav-link {
			padding: rem-calc(10 20);
			border-left: 3px solid transparent;
			color: $gray-700;
			
			&.active {
				border-left: 3px solid $secondary;
				background-color: $gray-100;
				color: $dark-navy;
				font-weight: $font-weight-bold;
			}
		}
	}
}

.pills-lg {

	.nav-pills {

		.nav-item {

			.nav-link {
				margin: rem-calc(0 30 15 0);

				@include media-breakpoint-up(xxl) {
					margin-bottom: 0;
				}
			}
		}
	}
}