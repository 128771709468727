.widget {
	background: white;
	padding: rem-calc(17 0);

	&.bg-lg {
		background-color: transparent;
		
		@include media-breakpoint-up(lg) {
			background-color: white;
		}
	}

	// .widget-content {
	// 	max-width: 100%;
	// 	overflow-x: auto;
	// }

	.container {
		padding-left: rem-calc(20);
		padding-right: rem-calc(20);
	}

	&.inner-content {
		
		@include media-breakpoint-down(md) {
		    background-color: transparent;
		}

		.container {
			padding-bottom: 0;

			@include media-breakpoint-down(md) {
			    padding: 0;
			}
		}
	}
}

.main {
	padding-top: rem-calc(70);
	margin-bottom: rem-calc(50);

	@include media-breakpoint-up(md) {
		padding-top: rem-calc(132);
	}

	@include media-breakpoint-up(xl) {
		padding-top: rem-calc(100);
	}

	.main-inner {

		@include media-breakpoint-up(xl) {
			padding: rem-calc(0 28);
		}
	}
}

.main form[action~="./WorkOrders.aspx?mobile=1"] {

	#contentPlaceHolder_divQuotesGrid, .nav-pills {
		display: none;
	}
}

.noRight input[type=checkbox] { 
    right: 0px !important;
}

img {
	max-width: 100%;
}

.container {

	&.inner {

		@include media-breakpoint-down(md) {
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
		}
	}
}

.checkcbox-list {

	.custom-control {
		display: inline-block;
	}

	label {
		padding-left: rem-calc(10);
	}
}

.signature {
	border: solid 1px $gray-400; 
	border-radius: rem-calc(3);
}

*:focus {
    outline: none;
}

textarea {
	resize: none;
}

.title {
	font-size: $h3-font-size;
	border-bottom: 1px solid rgba($gray-400, 0.3);
	padding-bottom: rem-calc(10);
}

.RB {
    border-right: 1px solid rgba($gray-400, 0.3);
    border-left: 1px solid rgba($gray-400, 0.3);
}

ul.ui-autocomplete {
    z-index: 10000;
}