.bootstrap-select {
	border: 1px solid transparent !important;

	&.disabled {

		&.form-control {
			background-color: $gray-200;
		}
	}

	&.show, &:focus {
		border: 1px solid $input-focus-border-color !important;
		box-shadow: $input-focus-box-shadow;
	}

	.filter-option-inner-inner {
		color: $body-color;
	}

	.btn {
		font-weight: $font-weight-normal;

		&.btn-light {
			border-color: transparent;
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}

			&:focus, &:active {
				border: 1px solid $input-focus-border-color !important;
				box-shadow: $input-focus-box-shadow;
			}
		}
	}

	.dropdown-item {

		&:focus, &.active {
			border: none;
			outline: none;
		}

		a {
			padding: rem-calc(10 24);
		}
	}
	.dropdown-menu {
		box-shadow: rem-calc(0 0 10) rgba($body-color, 0.3);
		color: $body-color;
	}

	.show {

		> .btn-light {

			&.dropdown-toggle {
				background-color: transparent;
			}
		}
	}

	> select.mobile-device:focus + .dropdown-toggle,
	.dropdown-toggle:focus {
		outline: none !important;
	  	outline: none !important;
	  	outline-offset: 0;
	}
}