.RadTreeView {

	label {
		display: flex !important;
		margin: 0;
	}

	.rtLines {

		.rtTop, .rtMid, .rtBot {
			display: flex;
		}
	}
}

.RadTreeView_Bootstrap {
	font-family: $font-family-sans-serif !important;
	color: $body-color !important;

	.rtPlus, .rtMinus {
		margin: rem-calc(0 0 0 -22) !important;
	}

	.rtIn {
		padding: 0 !important;
	}

	.rtHover {

		.rtIn {
			border-color: transparent !important;
			background-color: transparent !important;
		}
	}
}