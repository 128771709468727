// Color system

$gray-100: 						#EFF4F8;
$gray-200: 						#E3E8EC;
$gray-300:                      #cacfd3;
$gray-400:                      #aab2b8;
$gray-500:                      #e4e8eb;
$gray-700: 						#67778E;
$gray-800:                      #EAEBEC;
$gray-900:                      #212529;

$primary:       				$butterfields-blue;
$secondary:     				$electric-blue;
$success:       				$electric-green;
$info:          				$gray-700;
$dark:							$gray-700;
$warning: 						#f7941d;
$danger:            #EA0036;
$white:							#fcfcfc; 
$light:                         $gray-100;
$yiq-contrasted-threshold:      250;

$text-muted:                    $gray-400;

// Body

$body-bg:                   	$gray-100;
$body-color:                    $gray-900;

// Grid containers

$grid-breakpoints: (
  xs: 0,
  sm: 475px,
  md: 768px,
  lg: 992px,
  xl: 1250px,
  xxl: 1620px
);

$grid-breakpoint-lg: map_get($grid-breakpoints, "lg");
$grid-breakpoint-xl: map_get($grid-breakpoints, "xl");
$grid-breakpoint-xxl: map_get($grid-breakpoints, "xxl");

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
  xxl: 100%
);

// Typography

$font-size-base:              	rem-calc(14); // Assumes the browser default, typically `16px`
$font-size-md:                  rem-calc(16);
$font-size-lg:					rem-calc(18);
$font-size-sm:                	rem-calc(14);

$font-weight-normal:			400;
$font-weight-bold:				500;
$font-weight-bolder:			600;

$headings-font-weight:        	$font-weight-normal;
$headings-color:				$dark-navy;

$display1-size:               	rem-calc(55);
$display2-size:                 rem-calc(42);

$h1-font-size:					rem-calc(27);
$h2-font-size:                	rem-calc(25);
$h3-font-size:                	rem-calc(22);
$h4-font-size:                	$font-size-base * 1.5;
$h5-font-size:                	$font-size-base * 1.25;
$h6-font-size:                	$font-size-base;

$font-family-sans-serif:      	canada-type-gibson, sans-serif;

$lead-font-size:                rem-calc(16);

$hr-border-color:               $gray-200;

// Forms
$input-color:                   $gray-900;
$input-bg: 						$gray-100;
$input-border-color: 			$gray-100;
$input-btn-padding-y:         	rem-calc(13);
$form-grid-gutter-width:        rem-calc(2);
$input-font-size-lg:            $lead-font-size;
$form-group-margin-bottom: rem-calc(13);

// Buttons + Forms

$btn-padding-y:         		rem-calc(12);
$btn-padding-x:         		rem-calc(10);

$btn-padding-y-lg:            	rem-calc(14);
$btn-padding-x-lg:            	rem-calc(23);
$btn-font-size-lg:				$font-size-base;

// Buttons

$btn-font-weight: 				$font-weight-bold;

// Navbar

$navbar-brand-padding-y:		rem-calc(15);

// Dropdowns

$dropdown-border-radius: 		0;
$dropdown-border-width:			0;
$dropdown-min-width:            rem-calc(275);

// Tables

$table-head-color: 				$dark-navy;
$table-border-color: 			rgba($gray-700, 0.15);
$table-accent-bg:         rgba($dark-navy, 0.05);

// Navs

$nav-pills-border-radius:		0;
$nav-pills-link-active-color:	$dark-navy;
$nav-pills-link-active-bg:		$secondary;
$nav-link-padding-y:			rem-calc(2);
$nav-link-padding-x:			0;

// Cards

$card-border-width:				0;
$card-spacer-y:					rem-calc(20);
$card-spacer-x:					1.25rem;
$card-border-color:             $gray-400;

// Components

$border-radius:               	.25rem;
$border-radius-lg:            	.3rem;
$border-radius-sm:            	.2rem;

// Modals

$modal-content-border-color:    transparent;
$modal-header-border-color:     $gray-200;
$modal-lg:                      900px;

// Bootstrap Select Overrides

$color-grey-arrow: $body-color;
$input-color-placeholder: $body-color;

// Dropdown

$dropdown-link-active-color:        $body-color;
$dropdown-link-active-bg:           $gray-200;
$dropdown-link-color:               $body-color;
$dropdown-link-hover-color:         darken($body-color, 10%);
$dropdown-item-padding-y:           .5rem;

// Tables

$table-cell-padding:          rem-calc(10);
$table-cell-padding-sm:       .3rem;