.alert {
	margin-top: rem-calc(15);
	display: block;
	position: relative;

	&.alert-danger {
	    color: $danger;
	    background-color: rgba($danger, 0.1);
	    border-color: transparent;
	    position: relative;
	    display: flex;
	    align-items: center;

	    &:before {
	    	content: '\e8a8';
	    	font-family: fontello;
	    	font-size: rem-calc(21);
    	    margin-right: rem-calc(10);
	    }

	    &:after {
	    	content: '';
	    	width: 0;
	    	height: 0;
	    	border-left: #{rem-calc(7)} solid transparent;
	    	border-right: #{rem-calc(7)} solid transparent;
	    	border-bottom: #{rem-calc(12)} solid rgba($danger, 0.1);
	    	position: absolute;
	    	top: #{rem-calc(-13)};
	    	left: #{rem-calc(22)};
	    }

	    .close {
	    	color: $danger;
	    }
	}

	&.alert-tip {
	    border-color: transparent;
	    position: relative;
	    display: flex;
	    align-items: center;
	    padding-left: rem-calc(50);

	    &:before {
	    	content: '\e8a8';
	    	font-family: fontello;
	    	font-size: rem-calc(21);
    	    position: absolute;
    	    left: rem-calc(20);
	    }

	    &:after {
	    	content: '';
	    	width: 0;
	    	height: 0;
	    	border-left: #{rem-calc(7)} solid transparent;
	    	border-right: #{rem-calc(7)} solid transparent;
	    	border-bottom: #{rem-calc(12)} solid rgba(transparent, 0.1);
	    	position: absolute;
	    	top: #{rem-calc(-13)};
	    	left: #{rem-calc(22)};
	    }

	    &.alert-warning {

	    	&:after {
	    		border-bottom: #{rem-calc(12)} solid lighten($warning, 35%);
	    	}
	    }

	    &.alert-info {

	    	&:after {
	    		border-bottom: #{rem-calc(12)} solid lighten($info, 40%);
	    	}
	    }
	}

	&.w-button {
		padding-right: rem-calc(45);
	}

	&.footer-alert {
		margin-right: 0;
		margin-top: 0;
	}

	.close {
		position: absolute;
		right: rem-calc(17);
	}

	&.alert-sm {
		padding: $btn-padding-y-sm $btn-padding-x-sm;
		display: inline-block;

		&.alert-danger {

			&:before {
				content: none;
			}

			&:after {
				content: none;
			}
		}
	}
}