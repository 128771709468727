.navbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;

	&.main {
		border: none;
		padding: 0;
		margin: 0;
		display: flex;

		a {

			&:hover {
				text-decoration: none;
			}
		}

		.navbar-brand {
			background-color: white;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: rem-calc(75);
			height: rem-calc(70);
			padding-top: rem-calc(10);
			padding-bottom: rem-calc(10);
			border-left: #{rem-calc(5)} solid $secondary;
			margin-right: 0;

			img {
				height: 100%;
			}

			@include media-breakpoint-up(xl) {
				width: rem-calc(115);
				height: rem-calc(100);
				padding-top: $navbar-brand-padding-y;
				padding-bottom: $navbar-brand-padding-y;
				border-left: #{rem-calc(8)} solid $secondary;
			}
		}

		.navbar-collapse {
			position: fixed;
		    top: rem-calc(70);
		    bottom: 0;
		    left: 0;
		    right: 0;
		    overflow-y: auto;
		    pointer-events: all;
		    background-color: $primary;
		    transition: none;

		    @include media-breakpoint-up(xl) {
		    	position: relative;
		    	top: 0;
		    	overflow-y: visible;
		    	height: rem-calc(100);
		    	background-color: transparent;
			}

		    &.collapse:not(.show) {
		        display: none;
		    }

		    .navbar-toggler {
		    	position: fixed;
		    	top: 0;
		    	left: 0;
		    	bottom: 0;
		    	right: 0;
		    }
		}

		.navbar-nav {
			background-color: $primary;

			@include media-breakpoint-up(xl) {
				padding: 0;
				height: 100%;
			}

			li {
				border-bottom: #{rem-calc(1)} solid rgba($white, 0.05);
				width: 100%;
				height: 100%;
				font-size: rem-calc(14);
				position: relative;

				&.active {
					background-color: darken($primary, 5%);

					i {
						color: $secondary;
					}
				}

				&.search {

					input {
						font-size: rem-calc(18);
						border-top-right-radius: $border-radius;
						border-bottom-right-radius: $border-radius;

						@include media-breakpoint-up(lg) {
							font-size: rem-calc(25);
						}
					}

					.container {
						display: block;
					}

					.dropdown-menu {

						&.fat {
							padding: rem-calc(55 0 20 0);

							@include media-breakpoint-up(lg) {
								padding: rem-calc(50 0 35 0);
							}
						}
					}

					.search-inner {
						padding: 0;
						margin: 0;

						.help {
							display: flex;
							align-items: center;
							justify-content: center;

							#searchHelp {
								display: flex;
								align-items: center;
								justify-content: center;
								cursor: pointer;

								i {
									display: flex;
									align-items: center;
									justify-content: center; 
									font-size: rem-calc(36);
								}
							}
						}

						@include media-breakpoint-up(md) {
							padding: rem-calc(0 58);
						}

						@include media-breakpoint-up(xl) {
							padding: rem-calc(0 100);
						}
					}
				}

				@include media-breakpoint-up(xl) {
					border: none;
					width: rem-calc(95);
					display: inline-flex;
					align-items: center;
					justify-content: center;
				}

				a {
					color: white;
					width: 100%;
					display: flex;
					align-items: center;
					padding: rem-calc(20 15);

					@include media-breakpoint-up(xl) {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						padding: 0;
					}

					i {
						font-size: rem-calc(35);
						display: flex;
					    align-items: center;
					    width: rem-calc(35);

						@include media-breakpoint-up(xl) {
							display: block;
							line-height: 1.3;
							height: rem-calc(45);
							width: auto;
						}

						&:before {
							margin: 0;
						}
					}

					span {

						&:not(.badge) {
							transition: border 0.4s;
							margin: 0;
							padding-left: rem-calc(20);

							@include media-breakpoint-up(xl) {
								display: block;
								border-bottom: #{rem-calc(4)} solid transparent;
								padding-left: 0;
							}
						}
					}

					&:hover {

						span {

							@include media-breakpoint-up(xl) {
								border-bottom: #{rem-calc(4)} solid $secondary;
							}
						}
					}

					#lblTimesheetTime {
						margin-left: rem-calc(10);

						@include media-breakpoint-up(xl) {
							position: absolute;
							bottom: rem-calc(-10);
							border-bottom: none;
							margin-left: 0;
						}
					}
				}

				.dropdown-menu {
					box-shadow: rem-calc(0 5 30) rgba($gray-700, 0.1);
					margin-top: 0;
					padding: 18px 0;

					.dropdown-item {
						padding: 0;

						a {
							color: $body-color;
							position: relative;
							padding: rem-calc(15 25);
							text-align: left;
							display: block;
							position: relative;

							@include media-breakpoint-up(lg) {
								padding: rem-calc(8 29);
							}

							&.close {
								position: absolute;
								width: rem-calc(45);
								height: rem-calc(45);
								right: rem-calc(15);
								top: 0;
								left: auto;
								color: $gray-700;
								opacity: 1;

								@include media-breakpoint-up(lg) {
									top: rem-calc(10);
								}

								i {
									font-size: rem-calc(25);
								}

								&:before {
									content: none;
								}
							}

							&:before {
								content: '\f105';
								font-family: fontello;
								padding-right: rem-calc(15);
								color: $secondary;
							}
						}
					}



					.heading {
						padding: rem-calc(15 25);
					}
					
					&.fat {

						@include media-breakpoint-up(xl) { 
							position: fixed;
							top: rem-calc(100);
							left: 0;
							right: 0;
							overflow-y: auto;
							padding: rem-calc(50 0);
						}
					}
				}
			}		
		}

		.user-menu {
			position: fixed;
			right: rem-calc(55);

			@include media-breakpoint-up(xl) {
				right: rem-calc(62);
				height: 100%;

				#navNav {
					height: 100%;

					ul, li {
						height: 100%;
					}
				}
			}

			.fat {

				@include media-breakpoint-down(xl) {
					position: fixed;
					top: rem-calc(68);
					left: 0;
					right: 0;
					overflow-y: auto;
					padding: rem-calc(50 0);
				}
			}

			.navbar-nav {
				flex-direction: row;
				flex-wrap: nowrap;
				background-color: transparent;
			}

			.nav-item {
				height: rem-calc(70);

				@include media-breakpoint-up(lg) {
					height: 100%;
				}

				@include media-breakpoint-up(xl) {
					width: rem-calc(95);
				}
				
				@include media-breakpoint-up(xxl) {
					width: rem-calc(100);
				}

				.nav-link {
					padding: rem-calc(17 8);
					min-height: rem-calc(70);

					@include media-breakpoint-up(lg) {
						min-height: rem-calc(85);
					}

					@include media-breakpoint-up(xl) {
						min-height: auto;
					}

					&:focus, &:hover, &:-webkit-direct-focus {
						outline: none;
						outline-width: 0;
					}

					&:-webkit-direct-focus {
						outline: none;
						outline-width: 0;
					}

					@include media-breakpoint-up(xl) {
						padding: rem-calc(17);
					}

					span {
						display: none;

						@include media-breakpoint-up(xl) {
							display: block;
						}
					}

					.signin-status {
						position: relative;
						text-align: center;

						.inner {
						
							.status {
								display: none;

								@include media-breakpoint-up(xl) {
									display: inline;
								}
							}
						}

						.signin {
							display: block;

							&:before {
								font-family: fontello;
								content: '\e8ba';
								display: block;
								font-size: 2.1875rem;
								line-height: 1.1;
							    height: 2.8125rem;
							    width: auto;
							    color: $success;

							    @include media-breakpoint-up(lg) {
							    	line-height: 1.3;
							    }

							    @include media-breakpoint-up(xl) {
							    	line-height: 1.1;
							    }
							}
						}

						.signout {
							display: block;

							&:before {
								font-family: fontello;
								content: '\e8b9';
								display: block;
								font-size: 2.1875rem;
								line-height: 1.1;
							    height: 2.8125rem;
							    width: auto;
							    color: $warning;

							    @include media-breakpoint-up(lg) {
							    	line-height: 1.5;
							    }

							    @include media-breakpoint-up(xl) {
							    	line-height: 1.1;
							    }
							}
						}
					}

					&:hover {

						.status {
							border: none;
						}

						@include media-breakpoint-up(xl) {
							.signin {
								border-bottom: #{rem-calc(4)} solid $success;
							}

							.signout {
								border-bottom: #{rem-calc(4)} solid $warning;
							}
						}
					}
				}

				// &.dropdown {

				// 	.status {

				// 		&:after {
				// 			content: '\f107';
				// 			font-family: fontello;
				// 			padding-left: rem-calc(5);
				// 		}
				// 	}

				// 	&.show {

				// 		.status {

				// 			&:after {
				// 				content: '\f106';
				// 				font-family: fontello;
				// 			}
				// 		}
				// 	}
				// }
			}

			@include media-breakpoint-up(xl) {
				position: absolute;
				right: rem-calc(30);
			}

			i {
				font-size: rem-calc(40);
				line-height: 1.3;
			}

			.dropdown-menu {
				position: fixed;
				top: rem-calc(70);

				@include media-breakpoint-up(xl) {
					right: 0;
					left: auto;
					position: absolute;
					top: 100%;
				}
			}
		}

		.dropdown-toggle {

			&::after {
				content: none;
			}
		}

		.dropdown-menu {

			li {

				a {
					background-color: white;
					transition: background-color 0.1s;
				}

				&:hover {

					a {
						background-color: $gray-100;
					}
				}
			}
		}
	}
}

#workOrdersNav {

	.card {

		&:hover {
			cursor: pointer;
		}
	}
}

#ulSignInOut {

	&.signout {

		.dropdown-item {

			&.signout {
				display: none;
			}
		}
	}

	&.signin {

		.dropdown-item {

			&.signin {
				display: none;
			}
		}
	}
}

.mobile-filters {

	a {

		&:hover {
			cursor: pointer;
		}
	}
}


@media screen and (max-height: 750px) {

	.navbar {
		justify-content: space-between;

		&.main {

			.navbar-collapse {

				&.collapse:not(.show) {
					display: none !important;
				}
			}

			.user-menu {

				.nav-item {
					width: 100% !important;

					.nav-link {

						.status, .logged-in-user, .hide-max-height {
							display: none !important;
						}
					}
				}

				.dropdown-menu {

					&.show {
						position: absolute !important;
						margin-top: 1px;

						@include media-breakpoint-up(lg) {
							margin-top: 8px;
						}

						@include media-breakpoint-up(xl) {
							margin-top: 1px;
						}
					}



					&.fat {
						position: fixed !important;
						top: 68px !important;

						.close {
							display: none !important;
						}
					}
				}
			}

			.navbar-nav {


				li {

					a {
						position: relative !important;
					}

					.dropdown-menu {

						.dropdown-item {

							a {
								padding: rem-calc(8 29) !important;
								color: $body-color !important;
								text-align: left !important;
								display: block !important;
							}
						}
					}
				}

				.nav-item {

					&.dropdown {
						display: block !important;
					}
				}
			} 
		}

		.navbar-nav {

			.dropdown-menu {
			    position: static !important;
			}
		} 
	}

	.navbar-toggler {
		display: block !important;
	}

	.navbar-brand {
		width: rem-calc(75) !important;
		height: rem-calc(70) !important;
	}

	@include media-breakpoint-up(xl) {

		.navbar-brand {
			width: rem-calc(75) !important;
			height: rem-calc(70) !important;
		}
		
		.navbar {

			&.main {

				.navbar-brand {
				    background-color: white !important;
				    display: inline-flex !important;
				    align-items: center !important;
				    justify-content: center !important;
				    width: rem-calc(75) !important;
				    height: rem-calc(70) !important;
				    padding-top: rem-calc(10) !important;
				    padding-bottom: rem-calc(10) !important;
				    border-left: #{rem-calc(5)} solid $secondary !important;
				    margin-right: 0 !important;
				}

				.navbar-nav {

					li {
						border-bottom: #{rem-calc(1)} solid rgba($white, 0.05) !important;
						width: 100% !important;
						height: 100% !important;
						font-size: rem-calc(14) !important;
						position: relative !important;

						a {
							flex-direction: row !important;
							color: white !important;
						    width: 100% !important;
						    display: flex !important;
						    align-items: center !important;
						    padding: rem-calc(20 15) !important;
						    justify-content: flex-start !important;

						    i {
						    	display: flex !important;
						    	height: auto !important;
						    	width: rem-calc(35) !important;
						    }
						}
					}
				}

				.user-menu {
					right: rem-calc(55) !important;

					.nav-item {

						.nav-link {
							padding: rem-calc(17 8) !important;
						}
					}

					#navNav {

						ul {
							flex-direction: row !important;
						}
					}
				}

				.navbar-nav {
					flex-direction: column !important;
					width: 100% !important;

					li {

						a {

							span:not(.badge) {
								padding-left: rem-calc(20) !important;
								border-bottom: none !important;
							}
						}
					}
				}

				.navbar-collapse {
				    position: fixed !important;
				    top: rem-calc(70) !important;
				    bottom: 0 !important;
				    left: 0 !important;
				    right: 0 !important;
				    overflow-y: auto !important;
				    pointer-events: all !important;
				    background-color: $primary !important;
				    transition: none !important;
				    height: auto !important;
				}
			}
		}
	}
}