/*override telerik css*/
.rsAptContent {
    padding-left: 10px !important;
    padding-top: 0px !important;
    font-size: 13px !important;
}

.rsHeader {
    z-index: 999 !important;
    height: 35px !important;
    border-color: #D5D5D5 !important;
    background-image: none !important;
    background: -webkit-linear-gradient(top, #fafafa 0%, #e9e9e9 100%) !important;
    color: #333 !important;

    @include media-breakpoint-down(lg) {
        height: auto !important;
    }
}

    .rsHeader ul a {
        color: #333 !important;
        border: 1px solid #419641 !important;
        background-repeat: repeat-x;
        background: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%) !important;
        line-height: 26px !important;
        border-radius: 3px !important;
    }

.rsHeaderMonth:hover, .rsHeaderWeek:hover, .rsHeaderDay:hover {
    background: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%) !important;
    color: white !important;
}



.rsHeader a {
    color: #333 !important;
    font-size: 13px !important;
}

.rsHeader h2, .rsHeaderWeek, .rsHeaderDay, .rsHeaderMonth {
    color: #333 !important;
    text-transform: none !important;
}

.rsSelected em {
    font-size: 13px !important;
    color: #333 !important;
    border: 1px solid #419641 !important;
    line-height: 26px !important;
    border-radius: 3px !important;
}

    .rsSelected em:hover {
        color: #333 !important;
        border: 1px solid #419641 !important;
        background: transparent !important;
    }

.rsPrevDay, .rsNextDay, .rsDatePickerActivator {
    border-radius: 3px !important;
    border: 1px solid $body-color !important;
    background-color: $body-color !important;
    margin-right: 5px !important;
    height: 22px !important;
    width: 22px !important;
}

.rsDatePickerActivator {
    width: auto !important;
    background-color: $white !important;
    border-color: rgba($gray-700, 0.25) !important;
    position: relative;
    margin: 0 !important;
    padding-left: 45px !important;
    padding-top: 9px !important;
    padding-bottom: 4px !important;
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
    margin-bottom: 6px !important;

    @include media-breakpoint-up(md) {
        margin-bottom: 0 !important;
    }

    .p-i-calendar {
        position: absolute;
        top: -1px;
        bottom: 0;
        left: 0;
        height: auto !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 38px !important;
        background: $body-color;
        color: white;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
}

.rsPrevDay, .rsNextDay {
    color: white !important;
}

.rsHorizontalHeaderTable th {
    font-size: 13px !important;
}


.rsDatePickerCalendar {
    z-index: 1001 !important;
}

.rcTitle {
    color: #333 !important;
}

.RadCalendar.RadCalendar_Metro.rsDatePickerCalendar {
    min-height: 200px !important;
}

.rcFastPrev, .rcPrev, .rcNext, .rcFastNext, .rcMView_PrevY, .rcMView_NextY {
    color: #767676 !important;
    background-image: none !important;
}


.RadScheduler .rsCategoryRed .rsAptContent, .RadScheduler .rsCategoryRed .rsAptIn, .RadScheduler .rsCategoryBlue .rsAptMid, .RadScheduler .rsCategoryRed .rsResourceMarker {
    color: white !important;
    border-top: none !important;
    background-color: #d9534f !important;
    background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%) !important;
}

.RadScheduler .rsCategoryBlue .rsAptContent, .RadScheduler .rsCategoryBlue .rsAptIn, .RadScheduler .rsCategoryBlue .rsAptMid, .RadScheduler .rsCategoryBlue .rsResourceMarker {
    color: white !important;
    border-top: none !important;
    background-color: #5bc0de !important;
    background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%) !important;
}

.RadScheduler .rsCategoryYellow .rsAptContent, .RadScheduler .rsCategoryYellow .rsAptIn, .RadScheduler .rsCategoryYellow .rsAptMid, .RadScheduler .rsCategoryBlue .rsResourceMarker {
    color: white !important;
    border-top: none !important;
    background-color: #ffbb1c !important;
    background-image: linear-gradient(to bottom, #ffbb1c 0%, #e8a300 100%) !important;
}

.RadScheduler .rsCategoryGray .rsAptContent, .RadScheduler .rsCategoryGray .rsAptIn, .RadScheduler .rsCategoryGray .rsAptMid, .RadScheduler .rsCategoryGray .rsResourceMarker {
    color: white !important;
    border-top: none !important;
    background-color: gray !important;
    background-image: linear-gradient(to bottom, #b0b0b0 0%, gray 100%) !important;
}

.RadScheduler .rsCategoryGreen .rsAptContent, .RadScheduler .rsCategoryGreen .rsAptIn, .RadScheduler .rsCategoryGreen .rsAptMid, .RadScheduler .rsCategoryGreen .rsResourceMarker {
    color: white !important;
    border-top: none !important;
    background-color: gray !important;
    background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%) !important;
    /*background-image: linear-gradient(to bottom, #D0ECBB 0%, #B0CC9B 100%) !important;*/
}

.RadScheduler .rsCategoryPink .rsAptContent, .RadScheduler .rsCategoryPink .rsAptIn, .RadScheduler .rsCategoryPink .rsAptMid, .RadScheduler .rsCategoryPink .rsResourceMarker {
    color: white !important;
    border-top: none !important;
    background-color: gray !important;
    background-image: linear-gradient(to bottom, #FF96CA 0%, #ff69b4 100%) !important;
    /*background-image: linear-gradient(to bottom, #d1bcdf 0%, #f1dcff 100%) !important;*/
}

.rsArrowRight, .rsArrowLeft, .rsArrowTop, .rsArrowBottom {
    display: none !important;
}

.rsRow {
    min-height: 100px !important;
    height: 100px;
}

.RadScheduler_Metro .rsMonthView .rsOtherMonth {
    background-color: rgba(233, 233, 233, 1) !important;
}

.RadScheduler_Bootstrap {
    font-family: $font-family-base !important;
    color: $body-color !important;
}

.rsToolbar {
    margin-bottom: 6px !important;

    @include media-breakpoint-up(md) {
        margin-bottom: 0 !important;
    }

    .rsToday {
        padding: 7px 11px !important;
        margin-right: 3px !important;
        border-radius: $border-radius !important;
        border-color: rgba($gray-700, 0.25) !important;
    }

    &.rsViews {

        .rsHeaderDay, .rsHeaderWeek, .rsHeaderMonth {
            padding: 7px 12px;
            line-height: 1.6;
            border-color: rgba($gray-700, 0.25) !important;
            box-shadow: none !important;

            &:hover {
                background: $primary !important;
            }
        }

        .rsHeaderDay {
            border-top-left-radius: $border-radius !important;
            border-bottom-left-radius: $border-radius !important;
        }

        .rsHeaderMonth {
            border-top-right-radius: $border-radius !important;
            border-bottom-right-radius: $border-radius !important;
        }
    }
}

.RadCalendar .rcRow td {
    border-width: 0px !important;
    padding: 0 !important;
}

.RadCalendar .rcMain {
    width: 218px !important;
}

.RadScheduler .rsHeader .rsDatePickerAnimationWrapper {
    border-radius: $border-radius !important;
    box-shadow: 0 0 10px rgba($black, 0.2);
}



.RadScheduler {

    .RadCalendar_Bootstrap .rcWeek th {
        border: none !important;
    }

    .RadCalendar .rcMain .rcRow a {
        padding: 4px 5px 2px 2px !important;
    }

    .RadCalendar_Bootstrap .rcMain .rcRow .rcSelected a {
        border-color: transparent !important;
    }

    .RadCalendar .rcWeek th {
        padding: 4px 0 !important;
    }

    .RadCalendar .t-font-icon {

        &:before {
            content: '\f101' !important;
            font-family: fontello !important;
        }
    }

    .RadCalendar_Bootstrap .rcTitlebar .rcTitle {
        line-height: 1.8;
    }

    .RadCalendar .rcTitlebar .rcFastPrev, .RadCalendar .rcTitlebar .rcFastNext {
        height: 100%;
        margin: 0;
        width: rem-calc(22) !important;
    }

    .RadScheduler .RadCalendar .t-i-arrow-right:before {
        content: '\f105' !important;
        font-family: fontello !important;
    }

    .RadCalendar .rcTitlebar .rcNext, .RadCalendar .rcTitlebar .rcPrev, .RadCalendar_Bootstrap .rcTitlebar .rcFastPrev, .RadCalendar_Bootstrap .rcTitlebar .rcFastNext {
        background-image: none !important;
        text-indent: 0 !important;
        height: 100%;
    }

    .RadCalendar_Bootstrap .rcTitlebar .rcFastPrev {

        .t-font-icon  {

            &:before {
                content: '\f100' !important;
            }
        }
    }

    .RadCalendar_Bootstrap .rcTitlebar .rcPrev {

        .t-font-icon  {

            &:before {
                content: '\f104' !important;
            }
        }
    }

    .RadCalendar_Bootstrap .rcTitlebar .rcNext {

        .t-font-icon  {

            &:before {
                content: '\f105' !important;
            }
        }
    }

    .p-icon {

        &.p-i-arrow-60-left {

            &:before {
                content: '\e8b4' !important;
                font-family: fontello !important;
            }
        }

        &.p-i-arrow-60-right {

            &:before {
                content: '\e8be' !important;
                font-family: fontello !important;
            }
        }
    }
}

.RadScheduler .rsApt .rsAptContent {
    padding-top: 8px !important;
    padding-bottom: 18px !important;
}