.checkbox-list {
	border: none;

	.custom-control {
		display: inline;
		margin-right: rem-calc(15);
	}

	tr {

		&:hover {
			background-color: transparent;

			@include media-breakpoint-up(md) {
				background-color: $table-hover-bg;
			}
		}
	}

	td {
		width: 100%;
		display: block;
		border: none;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;

		@include media-breakpoint-up(md) {
			width: auto;
			display: table-cell;
			border: $table-border-width solid $table-border-color;
			padding-top: $table-cell-padding;
			padding-left: $table-cell-padding;
			padding-right: $table-cell-padding;
		}
	}
}